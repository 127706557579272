export default {
  role: 'Role',
  roles: 'Roles',
  rolesList: 'Roles List',
  addNewRole: 'Add new role',
  editRole: 'Edit role',
  roleRemoved: 'Role removed',
  roleUpdated: 'Role updated',
  roleAdded: 'Role added',
  permissions: 'permissions',
  permissionName: {
    financial: 'financial',
    admin: {
      self: 'admin',
      info: 'base info'
    },
    round: {
      self: 'round',
      operation: 'operation'
    },
    movement: {
      self: 'movement',
      operation: 'operation'
    },
    player: {
      self: 'player',
      operation: 'operation'
    },
    team: {
      self: 'team',
      operation: 'operation'
    },
    competition: {
      self: 'competition',
      operation: 'operation'
    },
    level: {
      self: 'level',
      operation: 'operation'
    },
    grade: {
      self: 'grade',
      operation: 'operation'
    },
    'user-management': {
      self: 'user-management',
      operation: 'operation'
    },
    location: {
      self: 'location',
      cities: {
        self: 'cities',
        operation: 'cities operation'
      },
      countries: {
        self: 'countries',
        operation: 'countries operation'
      },
      provinces: {
        self: 'provinces',
        operation: 'provinces operation'
      },
      districts: {
        self: 'districts',
        operation: 'districts operation'
      }
    }
  }
  // others
}

const a = [
  'institutes.operation',
  'location.cities.operation',
  'location.countries.operation',
  'location.districts.operation',
  'location.provinces.operation',
  'user-management.operation'
]
