<template>
  <span> {{ sampleValue }} </span>
</template>

<script>
export default {
  name: 'DictionaryDisplay',
  props: {
    value: {type: Object, default: null}
  },
  computed: {
    sampleValue() {
      return this.$getLangText(this.value)
    }
  }
}
</script>
