export default {
  creditTransactionRemoved: 'تراکنش حذف شد',
  creditTransactionUpdated: 'تراکنش به روز رسانی شد',
  creditTransactionAdded: 'تراکنش افزوده شد',
  creditTransactions: 'تراکنش ها',
  creditTransaction: 'تراکنش',
  decrement: 'کاهش اعتبار',
  increment: 'افزایش اعتبار',
  wallet: 'کیف پول',
  GIFT: 'هدیه',
  CASH: 'نقدی',
  transactionId: 'شناسه تراکنش'
}
