<template>
  <page-loading v-if="initializing" />

  <offline-screen v-else-if="isOffline" />
  <component v-else :is="layout">
    <router-view />
  </component>
</template>

<script>
import PageLayout from '@/layout/index.vue'
import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {
    PageLayout,
    OfflineScreen: () => import('./layout/components/OfflineScreen')
  },
  data() {
    return {
      initializing: true
    }
  },
  async created() {
    try {
      await this.initApp()
    } catch (error) {
      this.$showError(error)
    } finally {
      this.initializing = false
    }
  },
  computed: {
    ...mapState({isOffline: '$offline'}),
    layout() {
      if (!this.$route.meta.layout) {
        return 'PageLayout'
      }
      return 'div'
    }
  },
  methods: {
    initApp() {
      return Promise.all([this.getSettings(), this.getCountryCodes()])
    },
    async getSettings() {
      const filter = {include: ['password', 'signIn', 'profile', 'registration', 'language', 'currency']}
      const settings = await this.$api.public.getSettings(filter)
      this.$store.commit('setSettings', settings)
    },
    async getCountryCodes() {
      const countryCodes = await this.$api.public.getCountryCodes()
      this.$store.commit('setCountryCodes', countryCodes)
    }
  },
  metaInfo() {
    const meta = {
      title: null,
      link: [{rel: 'favicon', href: 'favicon/favicon.ico'}]
    }
    const titleParts = [this.$t('base.appTitle')]
    const routeTitle = this.$route.meta.title
    if (routeTitle) {
      titleParts.unshift(this.$t(routeTitle))
    }
    meta.title = titleParts.join(' | ')
    return meta
  }
}
</script>
