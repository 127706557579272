<template>
  <span dir="ltr">{{ humanize }} </span>
</template>

<script>
export default {
  name: 'TimeDisplay',
  props: {
    value: {type: String, default: null}
  },
  computed: {
    humanize() {
      if (!this.value) return null
      return this.value.split(':').slice(0, 2).join(':')
    }
  }
}
</script>
