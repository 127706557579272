<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template #activator="{on}">
      <c-input :label="label" :value="sampleValue" :textarea="textarea" :rows="rows" v-on="on" readonly :rules="rules" v-bind="$attrs" />
    </template>

    <v-card class="rounded-0">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>{{ label }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn dark text @click="submit"> {{ $t('base.save') }} </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text v-if="tabs" class="pt-3">
        <v-tabs v-model="language">
          <v-tab v-for="lang in languages" :key="lang.code">
            {{ lang.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="language">
          <v-tab-item v-for="lang in languages" :key="lang.code" class="pt-3">
            <CInput v-model="lang.value" :textarea="textarea" auto-grow class="mb-3" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-text v-else class="pt-3">
        <c-input
          v-for="lang in languages"
          v-model="lang.value"
          :label="lang.name"
          :textarea="textarea"
          auto-grow
          class="mb-3"
          :key="lang.code"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FieldDictionary',
  props: {
    value: {type: Object, default: null},
    label: {type: String, default: null},
    rows: {type: [String, Number], default: 8},
    textarea: {type: Boolean, default: false},
    tabs: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null}
  },
  data() {
    const langs = this.$store.state.$settings?.languages || []
    const languages = langs.map((item) => ({...item, value: null}))
    return {
      dialog: false,
      language: 0,
      languages
    }
  },
  created() {
    this.init()
  },
  computed: {
    sampleValue() {
      const firstItemWithValue = this.languages.find((item) => item.value)
      return firstItemWithValue?.value
    }
  },
  methods: {
    cancel() {
      this.init()
      this.dialog = false
    },
    init() {
      const value = this.value || {translations: {}}
      this.languages.forEach((item) => {
        item.value = value?.translations?.[item.code] || null
      })
    },
    submit() {
      const langs = {translations: {}}
      let hasData = false
      this.languages.forEach((item) => {
        if (item.value) {
          hasData = true
          langs.translations[item.code] = item.value
        }
      })

      const value = hasData ? langs : null
      this.$emit('input', value)
      this.dialog = false
    }
  }
}
</script>
