export default {
  level: 'Level',
  levels: 'Levels',
  levelsList: 'Levels List',
  addNewLevel: 'Add new level',
  editLevel: 'Edit level',
  levelRemoved: 'Level removed',
  levelUpdated: 'Level updated',
  levelAdded: 'Level added',
  defaultRepeatCount: 'Default repeat count',
  defaultSetCount: 'Default set count'
}
