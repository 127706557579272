import store from '../store'
import config from '@/config'

import 'moment-timezone'
import moment from 'moment-jalaali'

export function getDateNow({startOfDay} = {}) {
  let m = moment.utc().tz(config.timeZone.name)
  if (startOfDay) {
    m = m.startOf('day')
  }
  return m.utc().toISOString().replace('Z', '')
}

export function toTimeZoneString(date = null) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  date = date ? moment.utc(date) : moment.utc()
  return date.tz(timezone).format('YYYY/MM/DD HH:mm:ss')
}
export function toTimeZoneDateString(date = null) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  date = date ? moment.utc(date) : moment.utc()
  return date.tz(timezone).format('YYYY/MM/DD')
}

export function toTimeZoneTimeString(date = null) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  date = date ? moment.utc(date) : moment.utc()
  return date.tz(timezone).format('HH:mm:ss')
}

export function isObject(o) {
  return typeof o === 'object' && o !== null && o.constructor && Object.prototype.toString.call(o).slice(8, -1) === 'Object'
}

export function extend(target, src) {
  const noExtend = ['__proto__', 'constructor', 'prototype']
  Object.keys(src)
    .filter((key) => noExtend.indexOf(key) < 0)
    .forEach((key) => {
      if (typeof target[key] === 'undefined') target[key] = src[key]
      else if (isObject(src[key]) && isObject(target[key]) && Object.keys(src[key]).length > 0) {
        extend(target[key], src[key])
      } else {
        target[key] = src[key]
      }
    })
  return target
}

export function isEmpty(value) {
  if (isObject(value)) {
    return Object.keys(value).length === 0
  }
  return value == null || value.length === 0
}

export function mobileNormalize(mobile) {
  if (!mobile) return null
  else if (mobile.startsWith('+98')) {
    return mobile
  } else if (mobile.startsWith('0098')) {
    return `+${mobile.substr(2)}`
  } else if (mobile.startsWith('0')) {
    return `+98${mobile.substring(1)}`
  } else if (mobile.startsWith('9')) {
    return `+98${mobile}`
  } else return mobile
}

export function mobileDenormalize(mobile) {
  if (!mobile) return null
  else if (mobile.startsWith('+98')) {
    return `0${mobile.substr(3)}`
  } else if (mobile.startsWith('0098')) {
    return `0${mobile.substr(4)}`
  } else if (mobile.startsWith('0')) {
    return mobile
  } else if (mobile.startsWith('9')) {
    return `0${mobile}`
  } else return mobile
}

export function hasPermission(permission, allPermissions = store?.state?.$account?.permissions) {
  if (isEmpty(permission) || isEmpty(allPermissions)) return false
  return allPermissions.includes(permission)
}

export function clockToMinute(clock) {
  let [hours, minutes] = clock.split(':')
  hours = isNaN(+hours) ? 0 : +hours
  minutes = isNaN(+minutes) ? 0 : +minutes
  const clockInMinutes = hours * 60 + minutes
  return clockInMinutes
}
