export default {
  workoutConfig: 'قالب جلسه',
  workoutConfigs: 'قالب‌های جلسه',
  workoutConfigsList: 'لیست قالب‌های جلسه',
  addNewWorkoutConfig: 'افزودن قالب جلسه جدید',
  editWorkoutConfig: 'ویرایش قالب جلسه',
  workoutConfigRemoved: 'قالب جلسه حذف شد',
  workoutConfigUpdated: 'قالب جلسه به‌روزرسانی شد',
  workoutConfigAdded: 'قالب جلسه افزوده شد'
}
