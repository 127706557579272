<template>
  <span v-if="value" dir="ltr">
    <span> {{ humanizedDate }} </span>
    <span v-if="time"> - {{ humanizedTime }}</span>
  </span>
</template>

<script>
import {toTimeZoneDateString, toTimeZoneTimeString} from '@/lib/utils'
export default {
  name: 'DateDisplay',
  props: {
    value: {type: String, default: null},
    time: {type: Boolean, default: false}
  },
  computed: {
    model() {
      if (!this.value) {
        return null
      }
      if (!this.value.endsWith('Z')) {
        return this.value + 'Z'
      } else return this.value
    },
    humanizedDate() {
      return toTimeZoneDateString(this.model)
    },
    humanizedTime() {
      return toTimeZoneTimeString(this.model)
    }
  }
}
</script>
