import ApiBase from '@/modules/core/api-base'

export class WorkoutConfigItems extends ApiBase {
  constructor() {
    const path = 'admin/mgm/workout-configs/items'
    super(path)
  }
}

export default {
  workoutConfigItems: new WorkoutConfigItems()
}
