export default {
  movementType: 'نوع حرکت',
  movementTypes: 'نوع‌های حرکت',
  movementTypesList: 'لیست انواع حرکت‌',
  addNewMovementType: 'افزودن نوع حرکت جدید',
  editMovementType: 'ویرایش نوع حرکت',
  movementTypeRemoved: 'نوع حرکت حذف شد',
  movementTypeUpdated: 'نوع حرکت به‌روزرسانی شد',
  movementTypeAdded: 'نوع حرکت افزوده شد'
}
