export default {
  // main vee validate rules
  alpha_spaces: '{_field_} فقط میتواند شامل حروف الفبا و فاصله باشد',
  digits: '{_field_} فقط باید شامل اعداد و دقیقا {length} کاراکتر باشد',
  email: 'فرمت ایمیل صحیح نمی باشد',
  image: '{_field_} باید عکس باشد',
  max: '{_field_} باید حداکثر {length} کاراکتر باشد',
  min: '{_field_} باید حداقل {length} کاراکتر باشد',
  confirmed: '{_field_} با تاییدیه اش مطابقت ندارد',
  numeric: '{_field_} فقط باید عدد(های) لاتین باشد',
  required: '{_field_} الزامی است',
  regex: '{_field_} صحیح نمی باشد',
  alpha_dash: '{_field_} فقط میتواند شامل حروف الفبا، اعداد و خط تیره باشد',
  max_value: '{_field_} باید کمتر یا برابر با {max} باشد',
  min_value: '{_field_} باید بیشتر یا برابر با {min} باشد',

  // custom rules
  betweenDate: '{_field_} باید بین تاریخ {min} و {max} باشد',
  betweenTime: '{_field_} باید بین {min} و {max} باشد',
  biggerThan: '{_field_} باید بیشتر از <span dir="ltr"> {number} </span> باشد',
  faChar: '{_field_} باید فارسی باشد',
  isDuplicate: 'تکراری است',
  isInvalid: '{_field_} معتبر نمی باشد',
  lessThan: '{_field_} باید کمتر از <span dir="ltr"> {number} </span> باشد',
  lowercase: 'حداقل یک حرف کوچک',
  maxDate: '{_field_} باید قبل از تاریخ {min} باشد',
  maxDecimal: '{_field_} باید حداکثر {decimal} رقم اعشار داشته باشد',
  maxTime: '{_field_} باید برابر یا کمتر از {max} باشد',
  minDate: '{_field_} باید بعد از تاریخ {min} باشد',
  minDecimal: '{_field_} باید حداقل {decimal} رقم اعشار داشته باشد',
  minTime: '{_field_} باید برابر یا بیشتر از {min} باشد',
  mobile: '{_field_} صحیح نمی باشد',
  mustBeExclude: '{_field_} باید غیر از {ref} باشد',
  uniqueChars: 'حداقل {uniqeChars} کاراکتر متفاوت',
  uppercase: 'حداقل یک حرف بزرگ',
  urlCheck: 'url وارد شده برای {_field_} صحیح نمی باشد ',
  invalid: '{_field_} صحیح نمی باشد',
  domainExist: 'این {_field_} قبلا ثبت شده است'
}
