import ApiBase from '@/modules/core/api-base'

export class PaymentConfigs extends ApiBase {
  constructor() {
    const path = 'admin/financial/payment-configs'
    super(path)
  }
}

export default {
  paymentConfigs: new PaymentConfigs()
}
