export default {
  workoutConfigItem: 'Workout config item',
  workoutConfigItems: 'Workout config items',
  workoutConfigItemsList: 'Workout config items List',
  addNewWorkoutConfigItem: 'Add new workout config item',
  editWorkoutConfigItem: 'Edit workout config item',
  workoutConfigItemRemoved: 'Workout config item removed',
  workoutConfigItemUpdated: 'Workout config item updated',
  workoutConfigItemAdded: 'Workout config item added',
  activityDuration: 'Activity duration',
  complexity: 'Complexity',
  restDuration: 'Rest duration',
  setCount: 'Set count'
}
