import {Account, AccountPub} from './account'
import Files from './files'
import {Public} from './public'

export default {
  account: new Account(),
  accountPub: new AccountPub(),
  files: new Files(),
  public: new Public()
}
