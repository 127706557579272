<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{errors}" tag="div">
    <label v-if="!searchForm && !innerLabel && label" class="mb-1 d-block"> {{ label }} </label>
    <VSelect ref="input" v-bind="inputAttrs" v-on="$listeners" :error-messages="errors[0]" />
  </ValidationProvider>
</template>

<script>
import {VSelect} from 'vuetify/lib'

export default {
  name: 'CSelect',
  inheritAttrs: false,
  components: {VSelect},
  props: {
    label: {type: String, default: null},
    innerLabel: {type: Boolean, default: false},
    searchForm: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null},
    inputClass: {type: String, default: null},
    inputStyle: {type: String, default: null},
    name: {
      type: String,
      default: function () {
        return this.label
      }
    }
  },
  computed: {
    inputAttrs() {
      const attrs = {
        ...this.$attrs,
        name: this.name,
        outlined: true,
        class: this.inputClass,
        style: this.inputStyle,
        dense: true,
        clearable: true
      }
      if (this.innerLabel) {
        attrs.label = this.label
      }
      if (this.searchForm) {
        attrs.label = this.label
        attrs.hideDetails = 'auto'
        attrs.class = 'ma-1'
      }
      return attrs
    }
  }
}
</script>
