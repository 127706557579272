export default {
  movementEvaluation: 'ارزیابی',
  movementEvaluations: 'ارزیابی‌ها',
  movementEvaluationsList: 'لیست ارزیابی‌ها',
  addNewMovementEvaluation: 'افزودن ارزیابی جدید',
  editMovementEvaluation: 'ویرایش ارزیابی',
  movementEvaluationRemoved: 'ارزیابی حذف شد',
  movementEvaluationUpdated: 'ارزیابی به‌روزرسانی شد',
  movementEvaluationAdded: 'ارزیابی افزوده شد',
  repeatCount: 'تعداد تکرار',
  activityTime: 'زمان فعالیت',
  restTime: 'زمان استراحت',
  setCount: 'تکرار در هر ست'
}
