export default {
  workoutStep: 'Workout step',
  workoutSteps: 'Workout steps',
  workoutStepsList: 'Workout steps List',
  addNewWorkoutStep: 'Add new workout step',
  editWorkoutStep: 'Edit workout step',
  workoutStepRemoved: 'Workout step removed',
  workoutStepUpdated: 'Workout step updated',
  workoutStepAdded: 'Workout step added',
  workoutStepsSorted: 'Workout steps sorted'
}
