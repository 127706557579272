import ApiBase from '@/modules/core/api-base'
import {ClientJS} from 'clientjs'
const client = new ClientJS()

export class AccountPub extends ApiBase {
  constructor() {
    const path = 'pub/account'
    super(path)
  }

  login(model) {
    let fingerprint = client.getFingerprint()
    model.uniqueId = fingerprint
    model.clientType = 'WEB'
    return this._request({
      action: 'login',
      method: 'POST',
      body: model,
      headers: {scope: 'ADMIN'}
    })
  }

  loginByOtp(model) {
    let fingerprint = client.getFingerprint()
    model.uniqueId = fingerprint
    model.clientType = 'WEB'
    return this._request({
      action: 'verify-otp',
      method: 'POST',
      body: model,
      headers: {scope: 'ADMIN'}
    })
  }

  sendOtp(model) {
    return this._request({
      action: 'send-otp',
      method: 'POST',
      body: model
    })
  }

  forgotPassword(model) {
    return this._request({
      action: 'forget-password',
      method: 'POST',
      body: model
    })
  }

  resetPassword(model) {
    return this._request({
      action: 'reset-password',
      method: 'POST',
      body: model
    })
  }
}

export class Account extends ApiBase {
  constructor() {
    const path = 'idn/account'
    super(path)
  }

  async logout() {
    return this._request({
      action: 'logout',
      method: 'POST'
    }).catch((err) => {
      if (err.status === 404) {
        this._store.commit('LOGOUT')
      }
      throw err
    })
  }

  profile() {
    return this._request({
      action: 'profile',
      method: 'GET'
    })
  }

  updateProfile(body) {
    return this._request({
      action: 'profile',
      method: 'PUT',
      body
    })
  }

  changePassword(body) {
    return this._request({
      action: 'change-password',
      method: 'POST',
      body
    })
  }

  deleteAccount() {
    return this._request({
      action: 'delete-user',
      method: 'POST',
    })
  }
}
