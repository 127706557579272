import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import {getItem} from '@/lib/local-storage'
import {hasPermission} from '@/lib/utils'
import store from '../store'
import {moduleRoutes} from '@/modules/core'
import i18n from '@/plugins/i18n'

Vue.use(Meta)
Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: '/',
  routes: moduleRoutes()
})

router.afterEach((to, from, next) => {
  store.commit('setLoading', false)
})

router.beforeEach(async (to, from, next) => {
  store.commit('setBreadcrumbs', [])
  store.commit('setLoading', true)

  const account = getItem('ACCOUNT')
  if (account) {
    store.commit('SET_ACCOUNT', account)
  }
  const publicPage = to.meta.auth === true
  const idnPage = to.meta.auth === 'Idn'
  const isLogin = !!account

  const fromLogin = from.path.startsWith('/login')

  if (publicPage) {
    next()
  } else if (isLogin) {
    if (idnPage) {
      next()
    } else {
      const hasAuthority = to.meta.auth.some((i) => hasPermission(i))
      if (hasAuthority) {
        next()
      } else {
        await Vue.prototype.$dialog.notify.error(i18n.t('base.errors.ACCESS_DENIED'), {
          position: 'bottom-right',
          timeout: 1500
        })
      }
    }
  } else if (!fromLogin) {
    const returnUrl = from.fullPath
    next({path: '/login', query: {returnUrl}})
  }
})

export default router
