import ApiBase from '../core/api-base'

class CreditTransactions extends ApiBase {
  constructor(store) {
    const path = 'admin/financial/credit-transactions'
    super(path, store)
  }

  increase(body) {
    return this._request({
      action: 'increase',
      method: 'POST',
      body
    })
  }

  userBalance(id) {
    return this._request({
      action: `balance/${id}`,
      method: 'GET'
    })
  }

  decrease(body) {
    return this._request({
      action: 'decrease',
      method: 'POST',
      body
    })
  }
}

export default {
  creditTransactions: new CreditTransactions()
}
