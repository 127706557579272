import ApiBase from '@/modules/core/api-base'

export class MovementEvaluations extends ApiBase {
  constructor() {
    const path = 'admin/movement-evaluations'
    super(path)
  }
}

export default {
  movementEvaluations: new MovementEvaluations()
}
