export default {
  stage: 'Stage',
  stages: 'Stages',
  stagesList: 'Stages List',
  addNewStage: 'Add new stage',
  editStage: 'Edit stage',
  stageRemoved: 'Stage removed',
  stageUpdated: 'Stage updated',
  stageAdded: 'Stage added',
  evaluationConfig: 'Evaluate config',
  evaluationConfigInfo: {
    BEGINNER: 'Beginner',
    INTERMEDIATE: 'Intermediate',
    ADVANCED: 'Advanced',
    GOLD: 'Elite'
  }
}
