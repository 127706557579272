export default {
  countries: 'کشورها',
  country: 'کشور',
  addNewCountry: 'افزودن کشور جدید',
  editCountry: 'ویرایش کشور',
  countriesList: 'لیست کشور‌ها',
  countryAdded: 'کشور افزوده شد',
  countryUpdated: 'کشور به روزرسانی شد',
  countryRemoved: 'کشور حذف شد',

  cities: 'شهرها',
  city: 'شهرها',
  addNewCity: 'افزودن شهر جدید',
  editCity: 'ویرایش شهر',
  citiesList: 'لیست شهر‌ها',
  cityAdded: 'شهرها افزوده شد',
  cityUpdated: 'شهرها به روزرسانی شد',
  cityRemoved: 'شهرها حذف شد',

  provinces: 'استان ها',
  province: 'استان',
  addNewProvince: 'افزودن استان جدید',
  editProvince: 'ویرایش استان',
  provinceList: 'لیست استان‌ها',
  provinceAdded: 'استان افزوده شد',
  provinceUpdated: 'استان به روزرسانی شد',
  provinceRemoved: 'استان حذف شد',

  districts: 'منطقه ها',
  district: 'منطقه',
  addNewDistrict: 'افزودن منطقه جدید',
  editDistrict: 'ویرایش منطقه',
  districtsList: 'لیست منطقه‌ها',
  districtAdded: 'منطقه افزوده شد',
  districtUpdated: 'منطقه به روزرسانی شد',
  districtRemoved: 'منطقه حذف شد'
}
