import routes from './router'
import i18n from './i18n'
import api from './api'
import config from './config'

export default {
  name: 'base',
  config,
  routes,
  i18n,
  api
}
