import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from 'vuetify/lib/locale/en'
import fa from 'vuetify/lib/locale/fa'

import config from '@/config'
import { moduleI18n } from '@/modules/core'

const dictionary = moduleI18n()
Vue.use(VueI18n)

const messages = {
  en: {
    ...dictionary.en,
    $vuetify: en
  },
  fa: {
    ...dictionary.fa,
    $vuetify: fa
  }
}
export const i18n = new VueI18n({
  locale: config.locale,
  fallbackLocale: 'en',
  messages
})
export default i18n
