import ApiBase from '@/modules/core/api-base'

export class MovementTypes extends ApiBase {
  constructor() {
    const path = 'admin/movement-types'
    super(path)
  }
}

export default {
  movementTypes: new MovementTypes()
}
