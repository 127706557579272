import development from './development'
import production from './production'
import shared from './shared'

import {extend} from '@/lib/utils'
const config = {
  get development() {
    return extend(shared, development)
  },
  get production() {
    return extend(shared, production)
  }
}

const ENV_CONFIG = process.env.NODE_ENV
const env_config = config[ENV_CONFIG]

export default env_config
