import ApiCore from './api-core'
import {isEmpty} from '@/lib/utils'

export default class ApiBase extends ApiCore {
  getAll(params) {
    return this._request({
      method: 'GET',
      params
    })
  }

  getAllInfo(params) {
    return this._request({
      action: 'info',
      method: 'GET',
      params
    })
  }

  getById(id, params) {
    if (!id) return console.error('id must not be null')
    return this._request({
      method: 'GET',
      action: encodeURIComponent(id),
      params
    })
  }

  async getInfoById(id, params = {}) {
    params.id = id

    if (!id) return console.error('id must not be null')
    const items = await this.getAllInfo(params)
    if (isEmpty(items)) {
      throw {
        error: 'NOT_FOUND',
        errorCode: 1201,
        description: 'data not found'
      }
    }
    return items[0]
  }

  create(body) {
    return this._request({
      method: 'POST',
      body
    })
  }

  update(id, body) {
    if (!id) return console.error('id is undefined')
    return this._request({
      action: encodeURIComponent(id),
      method: 'PUT',
      body
    })
  }

  delete(id, params) {
    if (!id) return console.error('id is undefined')
    return this._request({
      method: 'DELETE',
      action: encodeURIComponent(id),
      params
    })
  }

  count(params) {
    return this._request({
      action: 'count',
      method: 'GET',
      params
    })
  }
}
