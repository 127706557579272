import ApiBase from '../core/api-base'

export class Tickets extends ApiBase {
  constructor(store) {
    const path = 'admin/tickets'
    super(path, store)
  }
}

export class TicketMessages extends ApiBase {
  constructor(store) {
    const path = 'admin/ticket-messages'
    super(path, store)
  }

  create(id, body) {
    return this._request({
      action: `ticket/${id}`,
      method: 'POST',
      body
    })
  }
}

export default {
  tickets: new Tickets(),
  ticketMessages: new TicketMessages()
}
