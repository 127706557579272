export default {
  license: 'لایسنس',
  licenses: 'لایسنس‌ها',
  addNewLicense: 'افزودن لایسنس جدید',
  editLicense: 'ویرایش لایسنس',
  licensesList: 'لیست لایسنس‌ها',
  licenseRemoved: 'لایسنس حذف شد',
  licenseUpdated: 'لایسنس به‌روزرسانی شد',
  licenseAdded: 'لایسنس افزوده شد',
  timeCondition: 'واحد زمان',
  timeConditionInfo: {
    ANY: 'نامحدود',
    DAY: 'روز',
    WEEK: 'هفته',
    MONTH: 'ماه',
    YEAR: 'سال'
  }
}
