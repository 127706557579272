<template>
  <v-card>
    <v-card-title v-if="title || $slots.title">
      <slot name="title"> {{ title }} </slot>
    </v-card-title>

    <v-card-text :class="bodyClass">
      <slot />
    </v-card-text>

    <v-card-actions v-if="$slots.actions" class="pa-4">
      <slot name="actions"> </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'FormCard',
  props: {
    title: {type: [String], default: null},
    bodyClass: {type: [String, Object], default: null}
  }
}
</script>
