<template>
  <div class="flex-grow-1">
    <CInput
      v-model="date"
      :label="label"
      readonly
      clearable
      @click:clear="clear"
      @click="show = true"
      v-bind="$attrs"
      prepend-inner-icon="mdi-calendar"
    />
    <persian-date-picker
      ref="datePicker"
      :id="'p-' + uniqueId"
      v-model="model"
      :show="show"
      :custom-input="`.${uniqueId}`"
      :format="format"
      auto-submit
      timezone
      @change="setDate"
      :locale="locale"
      :locale-config="localeConfig"
      @localeChange="currentLocale = $event.name"
      @close="show = false"
    />
  </div>
</template>

<script>
import 'moment-timezone'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import {isEmpty} from '@/lib/utils'

export default {
  name: 'DatePicker',
  inheritAttrs: false,
  props: {
    value: {type: [String, Array], default: null},
    label: String,
    format: {type: String, default: 'YYYY-MM-DDTHH:mm:ss'},
    time: {type: Boolean, default: false},
    range: Boolean
  },
  components: {
    PersianDatePicker: VuePersianDatetimePicker
  },
  data() {
    const localeConfig = this.$config.localeConfig
    const currentLocale = Object.keys(localeConfig)[0]
    const locale = Object.keys(localeConfig).join(',')
    const uniqueId = `date-picker-${Math.floor(Math.random() * 999999)}`

    return {
      uniqueId,
      currentLocale,
      localeConfig,
      locale,
      show: false,
      date: null
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        if (val != this.value) {
          this.$emit('input', val)
        }
      }
    }
  },
  mounted() {
    this.setDate(this.$refs.datePicker.date)
  },
  methods: {
    setDate(val) {
      this.$nextTick(() => {
        if (isEmpty(val) || isEmpty(this.model)) {
          this.date = null
          return
        }
        const displayFormat = this.localeConfig[this.currentLocale].displayFormat
        this.date = val.format(displayFormat)
      })
    },
    clear() {
      if (this.range) {
        this.model = []
      } else {
        this.model = null
      }
    }
  }
}
</script>
