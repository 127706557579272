export default {
  role: 'نقش',
  roles: 'نقش ها',
  addNewRole: 'افزودن نقش جدید',
  editRole: 'ویرایش نقش',
  rolesList: 'لیست نقش‌ها',
  roleRemoved: 'نقش حذف شد',
  roleUpdated: 'نقش به‌روزرسانی شد',
  roleAdded: 'نقش افزوده شد',
  permissions: 'دسترسی ها',
  permissionName: {
    financial: 'مالی',
    admin: {
      self: 'ادمین',
      info: 'اطلاعات پایه'
    },
    event: {
      self: 'نیازمندی ها',
      operation: 'عملیات',
      category: {
        self: 'دسته بندی ها',
        operation: 'عملیات'
      },
      report: {
        self: 'گزارش های نیازمندی',
        operation: 'عملیات'
      },
      service: {
        self: 'سرویس ها',
        operation: 'عملیات'
      },
      'service-unit': {
        self: 'واحدهای سرویس',
        operation: 'عملیات'
      }
    },
    identity: {
      self: 'identity',
      endpoints: {
        self: 'endpoints',
        operation: 'عملیات'
      },
      permissions: {
        self: 'دسترسی ها',
        operation: 'عملیات'
      },
      realms: {
        self: 'نواحی',
        operation: 'عملیات'
      },
      roles: {
        self: 'نقش ها',
        operation: 'عملیات'
      },
      userSessions: {
        self: 'نشست های کاربر',
        operation: 'عملیات'
      }
    },
    institutes: {
      self: 'موسسه ها',
      operation: 'عملیات'
    },
    'user-management': {
      self: 'کاربران',
      operation: 'عملیات'
    },
    location: {
      self: 'تقسیمات کشوری',
      cities: {
        self: 'شهرها',
        operation: 'عملیات'
      },
      countries: {
        self: 'کشورها',
        operation: 'عملیات'
      },
      provinces: {
        self: 'استان ها',
        operation: 'عملیات'
      },
      districts: {
        self: 'منطقه ها',
        operation: 'عملیات'
      }
    }
  }

  // others
}
