<template>
  <div class="d-inline-flex flex-wrap">
    <FileDisplay
      v-for="item in items"
      :key="item"
      class="me-2 mb-2"
      :value="item"
      v-bind="$attrs"
      :removable="removable"
      @remove="removeItem"
    />
  </div>
</template>

<script>
import FileDisplay from '@/components/base/FileDisplay'

export default {
  name: 'FilesDisplay',
  inheritAttrs: false,
  components: {FileDisplay},
  props: {
    value: {type: [Array], default: () => []},
    removable: {type: Boolean, default: false}
  },
  computed: {
    items: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    removeItem(item) {
      this.items = this.items.filter((i) => i !== item)
    }
  }
}
</script>
