<template>
  <div>
    <v-card>
      <div v-if="searchable" class="position-absolute pa-2 white rounded" :style="{top: '10px', left: '10px', zIndex: 55}">
        <c-input :label="$t('base.search')" outlined hide-details innerLabel @focus="search(term)" @blur="emitBlur" v-model="term" @input="search"></c-input>

        <v-card v-if="showResult" class="mx-auto" max-width="300" max-height="300" style="overflow-y: auto">
          <v-list-item v-for="item in searchResults" :key="item.id" @click="selectSearch(item)">
            <v-list-item-content class="font-90">
              {{ item.label }}
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>

      <l-map
        ref="map"
        :zoom="zoom"
        :options="{zoomControl: false, scrollWheelZoom: false}"
        :style="{height: `${height}px`}"
        @click="update"
      >
        <l-tile-layer :url="url" />
        <template v-for="(marker, i) in model">
          <l-marker
            v-if="marker.lat && marker.lng"
            :lat-lng="marker"
            :key="i"
            :draggable="multiple && !disabled"
            @update:latLng="($e) => updateMarker(marker, $e)"
          >
            <l-icon v-if="marker.icon" :icon-url="marker.icon" />
            <l-popup v-if="marker.text" dir="rtl">{{ marker.text }}</l-popup>
          </l-marker>
        </template>

        <l-polyline v-if="path && path.length" :lat-lngs="path" color="blue" />
        <l-control-zoom position="bottomleft"></l-control-zoom>
      </l-map>
    </v-card>

    <div v-if="errorMessage" class="text-center error--text font-90 mt-3">{{ errorMessage }}</div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import {LMap, LIcon, LPopup, LTileLayer, LMarker, LControlZoom, LPolyline} from 'vue2-leaflet'
import {Icon} from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import {OpenStreetMapProvider} from 'leaflet-geosearch'

const searchProvider = new OpenStreetMapProvider({
  params: {
    // 'accept-language': 'ir',
    // countrycodes: 'ir'
  }
})

export default {
  name: 'MapLocation',
  props: {
    value: {type: [Object, Array], default: null},
    zoom: {
      type: Number,
      default() {
        return this.$root.$isEmpty(this.value) ? 12 : 15
      }
    },
    path: {type: Array, default: () => []},
    height: {type: [Number, String], default: 350},
    errorMessage: String,
    multiple: {type: Boolean, default: false},
    searchable: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false}
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LIcon,
    LPopup,
    LPolyline
  },
  data() {
    return {
      term: null,
      showResult: false,
      searchResults: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateViewport()
    })
  },
  computed: {
    model: {
      get() {
        let val = []
        if (Array.isArray(this.value)) {
          val = this.value
        } else if (this.value && this.value.lat && this.value.lng) {
          val = [this.value]
        }
        return val
      }
    }
  },
  watch: {
    model() {
      this.updateViewport()
    }
  },
  methods: {
    updateViewport() {
      if (this.model.length > 1) {
        this.setBounds(this.model)
      } else if (this.model[0]) {
        this.setCenter(this.model[0])
      } else {
        this.setCenter(this.$config.defaultLocation)
      }
    },
    setBounds(bounds) {
      return this.$refs.map.fitBounds(bounds)
    },
    setCenter(value) {
      return this.$refs.map.setCenter(value)
    },
    search(term) {
      if (term && term.length) {
        searchProvider.search({query: term}).then((results) => {
          this.searchResults = results
          this.showResult = true
        })
      }
    },
    emitBlur() {
      setTimeout(() => {
        this.showResult = false
      }, 200)
    },
    selectSearch(item) {
      this.$emit('select', item)
      this.showResult = false
      const {x, y} = item
      const point = {lng: x, lat: y}
      this.emitInput(point)
      this.setBounds(item.bounds)
      this.term = null
      this.searchResults = []
    },
    updateMarker(marker, e) {
      marker.lat = e.lat
      marker.lng = e.lng
      fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.lat}&lon=${e.lng}&accept-language=ir`)
        .then((res) => res.json())
        .then((res) => {
          res.label = res.display_name
          this.$emit('update', res)
        })
    },
    update(e) {
      if (!this.multiple) {
        this.emitInput(e.latlng)
      }
    },
    emitInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>
