export default {
  workoutConfig: 'Exersice session',
  workoutConfigs: 'Exersice sessions',
  workoutConfigsList: 'Exersice sessions List',
  addNewWorkoutConfig: 'Add new exercise session',
  editWorkoutConfig: 'Edit exercise session',
  workoutConfigRemoved: 'Exersice session removed',
  workoutConfigUpdated: 'Exersice session updated',
  workoutConfigAdded: 'Exersice session added'
}
