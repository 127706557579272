export default {
  rank: 'Rank',
  ranks: 'Ranks',
  ranksList: 'Ranks List',
  addNewRank: 'Add new rank',
  editRank: 'Edit rank',
  rankRemoved: 'Rank removed',
  rankUpdated: 'Rank updated',
  rankAdded: 'Rank added',
  calorieRatio: 'Calorie ratio'
}
