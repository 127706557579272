import ApiBase from '@/modules/core/api-base'

export class Users extends ApiBase {
  constructor(store) {
    const path = 'admin/users'
    super(path, store)
  }
  getRoles(id, params) {
    return this._request({
      action: `${encodeURIComponent(id)}/roles`,
      method: 'GET',
      params
    })
  }

  updateRoles(id, body) {
    return this._request({
      action: `${encodeURIComponent(id)}/roles`,
      method: 'PATCH',
      body
    })
  }
}

export default {
  users: new Users(),
}
