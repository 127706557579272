import ApiBase from '@/modules/core/api-base'

export class Equipments extends ApiBase {
  constructor() {
    const path = 'admin/equipments'
    super(path)
  }
}

export default {
  equipments: new Equipments()
}
