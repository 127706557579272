export default {
  movement: 'حرکت',
  movements: 'حرکت‌ها',
  addNewMovement: 'افزودن حرکت جدید',
  editMovement: 'ویرایش حرکت',
  movementsList: 'لیست حرکت‌ها',
  movementRemoved: 'حرکت حذف شد',
  movementUpdated: 'حرکت به‌روزرسانی شد',
  movementAdded: 'حرکت افزوده شد',
  defaultRepeatCount: 'تعداد پیشفرش تکرار در ست',
  defaultSetCount: 'تعداد پیشفرش ست',
  complexities: 'پیچیدگی‌ها',
  valueType: 'نوع',
  valueTypeInfo: {
    TIME: 'زمان',
    COUNT: 'تعداد'
  }
}
