export default {
  equipment: 'Equipment',
  equipments: 'Equipments',
  equipmentsList: 'Equipments List',
  addNewEquipment: 'Add new equipment',
  editEquipment: 'Edit equipment',
  equipmentRemoved: 'Equipment removed',
  equipmentUpdated: 'Equipment updated',
  equipmentAdded: 'Equipment added',
  defaultRepeatCount: 'Default repeat count',
  defaultSetCount: 'Default set count'
}
