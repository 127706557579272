export default {
  homeUrl: '/dashboard',
  defaultLocation: {lat: 35.7, lng: 51.4},
  rtl: false,
  locale: 'en',
  tableFooterProps: {
    itemsPerPageOptions: [20, 50, 100]
  },
  timeZone: {
    name: 'Asia/Tehran',
    symbol: 'fa-ir'
  },
  localeConfig: {
    en: {
      tz: 'UTC',
      displayFormat: 'YYYY/MM/DD',
      lang: {label: 'Gregorian'}
    },
    // fa: {
    //   tz: 'Asia/Tehran',
    //   displayFormat: 'jYYYY/jMM/jDD',
    //   lang: {label: 'شمسی'}
    // }
  },
  baseCurrency: {
    name: 'Euro',
    symbol: '€',
    text: 'Euro',
    rate: 1
  },
  displayCurrency: {
    name: 'Euro',
    symbol: '€',
    text: 'Euro',
    rate: 1
  },
  get protocol() {
    return this.ssl ? 'https://' : 'http://'
  },
  get apiServerUrl() {
    return `${this.protocol}${this.apiServer}/api`
  },
  get websiteUrl() {
    return `${this.protocol}${this.website}`
  },
  get staticServerUrl() {
    return `${this.protocol}${this.staticServer}/files`
  }
}
