export default {
  creditTransactionRemoved: 'Credit transaction removed',
  creditTransactionUpdated: 'Credit transaction updated',
  creditTransactionAdded: 'Credit transaction added',
  creditTransactions: 'Credit transactions',
  creditTransaction: 'Credit transaction',
  decrement: 'Decrement credit',
  increment: 'Increment credit',
  wallet: 'Wallet',
  gift: 'Gift',
  cash: 'Cash',
  transactionId: 'Transaction Id'
}
