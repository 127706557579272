import ApiBase from '@/modules/core/api-base'
export class Countries extends ApiBase {
  constructor() {
    const path = 'admin/countries'
    super(path)
  }
}

export class Provices extends ApiBase {
  constructor() {
    const path = 'admin/provinces'
    super(path)
  }
}

export class Cities extends ApiBase {
  constructor() {
    const path = 'admin/cities'
    super(path)
  }
}

export class Districts extends ApiBase {
  constructor() {
    const path = 'admin/districts'
    super(path)
  }
}

export default {
  countries: new Countries(),
  provinces: new Provices(),
  cities: new Cities(),
  districts: new Districts(),
}
