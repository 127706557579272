export default {
  countries: 'Countries',
  country: 'Country',
  countriesList: 'Countries List',
  addNewCountry: 'Add new country',
  editCountry: 'Edit country',
  countryAdded: 'Country added',
  countryUpdated: 'Country updated',
  countryRemoved: 'Country removed',

  cities: 'Cities',
  city: 'City',
  citiesList: 'Cities List',
  addNewCity: 'Add new city',
  editCity: 'Edit city',
  cityAdded: 'City added',
  cityUpdated: 'City updated',
  cityRemoved: 'City removed',

  provinces: 'Provinces',
  province: 'Province',
  provincesList: 'Provinces List',
  addNewProvince: 'Add new province',
  editProvince: 'Edit province',
  provinceAdded: 'Province added',
  provinceUpdated: 'Province updated',
  provinceRemoved: 'Province removed',

  districts: 'Districts',
  district: 'District',
  districtsList: 'Provinces List',
  addNewDistrict: 'Add new district',
  editDistrict: 'Edit district',
  districtAdded: 'District added',
  districtUpdated: 'District updated',
  districtRemoved: 'District removed'
}
