<template>
  <div>
    <input
      type="file"
      class="d-none"
      ref="file"
      @change="upload"
      :accept="accept"
      v-bind="$attrs"
      :disabled="progressing"
      :multiple="multiple"
    />

    <ImageCropper v-if="!noCropper" ref="ImageCropper" @end="uploadFiles" :aspectRatio="aspectRatio" />

    <ValidationProvider :name="name" :rules="rules" v-slot="{errors}" tag="div">
      <label v-if="label" class="mb-3 d-block"> {{ label }} </label>
      <input type="hidden" />

      <div class="d-inline-flex flex-wrap">
        <FilesDisplay v-model="files" removable />

        <FileDisplay v-if="multiple || $isEmpty(files)" @click="openBrowser" class="mb-2">
          <span class="d-inline-block my-4">
            <v-progress-circular v-if="progressing" indeterminate color="primary" size="34" />
            <v-icon v-else :color="!$isEmpty(errors) ? 'error' : 'success'" size="34"> mdi-cloud-upload</v-icon>
          </span>
        </FileDisplay>
      </div>

      <div class="v-text-field__details">
        <div v-if="!$isEmpty(errors)" class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import FilesDisplay from './FilesDisplay'
import ImageCropper from './ImageCropper'
import FileDisplay from '@/components/base/FileDisplay'

export default {
  inheritAttrs: false,
  name: 'Uploader',
  props: {
    value: {type: String, default: null},
    multiple: {type: Boolean, default: false},
    noCropper: {type: Boolean, default: false},
    label: {type: [String], default: null},
    accept: {type: String, default: '.jpeg,.jpg,.png,.gif'},
    aspectRatio: {type: Number, default: 1},
    rules: {type: [String, Object], default: null},
    width: {type: String, default: '80'},
    height: {type: String, default: '80'},
    name: {
      type: [String],
      default: function () {
        return this.label
      }
    }
  },
  components: {FileDisplay, FilesDisplay, ImageCropper},
  data() {
    return {
      progressing: false
    }
  },
  computed: {
    files: {
      get() {
        if (this.$isEmpty(this.value)) return []
        return this.value.split(',')
      },
      set(val) {
        let newValue = null
        if (!this.$isEmpty(val)) {
          newValue = val.join(',')
        }
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    openBrowser() {
      this.$refs.file.click()
    },
    resetFileInput() {
      this.$refs.file.value = ''
    },
    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image'
    },
    upload(event) {
      if (!event.target.files.length) return

      const files = Array.from(event.target.files)
      const allFileAreImages = files.every(this.isFileImage)

      if (!this.noCropper && allFileAreImages) {
        this.$refs.ImageCropper.init(event.target.files)
      } else {
        this.uploadFiles(files)
      }
    },
    uploadFiles(files) {
      if (this.$isEmpty(files)) {
        return
      }

      this.progressing = true
      const model = new FormData()
      for (const file of files) {
        model.append('files', file)
      }
      return this.$api.files
        .upload(model)
        .then((res) => {
          this.files = this.files.concat(...res)
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.resetFileInput()
          this.progressing = false
        })
    }
  }
}
</script>
