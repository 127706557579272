export default {
  bodyPart: 'بخش بدن',
  bodyParts: 'بخش‌های بدن',
  bodyPartsList: 'لیست بخش‌ها بدن‌',
  addNewBodyPart: 'افزودن بخش بدن جدید',
  editBodyPart: 'ویرایش بخش بدن',
  bodyPartRemoved: 'بخش بدن حذف شد',
  mainBodyPartId: 'بخش بدن اصلی',
  bodyPartUpdated: 'بخش بدن به‌روزرسانی شد',
  bodyPartAdded: 'بخش بدن افزوده شد'
}
