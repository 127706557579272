const notifyConfig = {
  position: 'bottom-right',
  timeout: 1500
}
import {hasPermission, isEmpty, isObject} from '@/lib/utils'

export default {
  install: function (Vue) {
    Vue.mixin({
      computed: {
        $booleanOptions() {
          return [
            {text: this.$t('base.yes'), value: true},
            {text: this.$t('base.no'), value: false}
          ]
        }
      },
      methods: {
        // ******************** Notifications *******************
        $getLangText(dicInfo) {
          if (!dicInfo) {
            return null
          }
          const languages = this.$store.state.$settings?.languages || []
          for (const lang of languages) {
            const text = dicInfo.translations?.[lang.code]
            if (text) {
              return text
            }
          }
          return null
        },
        $showSuccess(body, go) {
          this.$dialog.notify.success(body, notifyConfig)
        },
        $showError(exceptions) {
          const body = this.$errorMessage(exceptions)
          this.$dialog.notify.error(body, notifyConfig)
        },
        $isEmpty(value) {
          return isEmpty(value)
        },
        $goto(path) {
          this.$router.push(path)
        },
        $setBreadcrumbs(breadcrumbs) {
          if (!isEmpty(breadcrumbs)) {
            breadcrumbs = breadcrumbs.reduce(
              (total, item) => {
                item.exact = true
                return total.concat(item)
              },
              [{to: this.$config.homeUrl, text: this.$t('base.appTitle'), exact: true}]
            )
          }

          this.$store.commit('setBreadcrumbs', breadcrumbs)
        },
        async $showWarning(text, title) {
          const config = {
            persistent: true,
            titleClass: 'text--warning',
            actions: {
              true: {
                text: this.$t('base.yes'),
                color: 'success'
              }
            },
            text: text,
            title: title || this.$t('base.warning')
          }
          return await this.$dialog.warning(config)
        },
        async $confirm(text, title) {
          const confirmConfig = {
            persistent: true,
            titleClass: 'text--warning',
            actions: {
              false: this.$t('base.no'),
              true: {
                text: this.$t('base.yes'),
                color: 'error'
              }
            },
            text: text || this.$t('base.areYouSureToRemove'),
            title: title || this.$t('base.warning')
          }
          return await this.$dialog.confirm(confirmConfig)
        },
        $errorMessage(exceptions) {
          const exception = exceptions[0]
          if (isEmpty(exception)) return exception

          const {status, error, description, errorCode} = exception
          let errorInfo = {}

          if (isObject(description)) {
            errorInfo = description
          }
          if (typeof description === 'string') {
            errorInfo.data = description
          }

          if (this.$te(`base.errors.${errorCode}`)) {
            return this.$t(`base.errors.${errorCode}`, errorInfo)
          }
          if (description) {
            return description
          }
          if (this.$te(`base.errors.${error}`)) {
            return this.$t(`base.errors.${error}`)
          }
          if (this.$te(`base.errors.${status}`)) {
            return this.$t(`base.errors.${status}`)
          }
          return this.$t(`base.errors.UNHANDLED_ERROR`, exception)
        },
        // ******************** Utils *******************
        mobileDenormalize(mobile) {
          return mobile.replace('+98', 0)
        },
        $hasPermission(p) {
          return hasPermission(p)
        },
        $filter(search = this.search) {
          const filter = {}
          Object.keys(search).forEach((key) => {
            if (isObject(search[key])) {
              if (search[key].min) filter[`${key}Min`] = search[key].min
              if (search[key].max) filter[`${key}Max`] = search[key].max
            } else if (!isEmpty(search[key])) {
              filter[key] = search[key]
            }
          })
          return filter
        },
        $scrollToTarget(target) {
          if (!target) console.error('No target Determinated')
          this.$nextTick(() => {
            const errorMessages = document.querySelector(target)
            if (!errorMessages) console.error('Target Not Found')
            const headerOffset = 130
            const elementPosition = errorMessages.getBoundingClientRect().top + window.scrollY
            const offsetPosition = elementPosition - headerOffset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            })
          })
        },
        $preSubmit(ref) {
          const validations = [this.$validator.validateAll()]
          if (ref) {
            const refs = this.$refs[ref]
            if (refs && Array.isArray(refs)) {
              refs.forEach((i) => {
                validations.push(i.$validator.validateAll())
              })
            } else if (refs && refs.$validator) {
              validations.push(refs.$validator.validateAll())
            }
          }
          return Promise.all(validations).then((isValidArray) => {
            const areValid = isValidArray.every((i) => i)
            // if (!areValid) {
            //   this.$scrollToTarget('.v-messages__message')
            // }
            this.progressing = areValid
            return areValid
          })
        }
      }
    })
  }
}
