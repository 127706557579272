<template>
  <div>
    <label v-if="!searchForm && !innerLabel && label" class="mb-1 d-block"> {{ label }} </label>
    <div class="d-flex align-center" :class="{'ma-1': searchForm}">
      <VSelect
        v-model="countryCode"
        v-bind="inputAttrs"
        class="me-1"
        :items="countryCodes"
        @input="setPrefix"
        item-text="abbreviation"
        item-value="abbreviation"
        style="flex-basis: 80px; max-width: 120px"
      >
        <template #item="{item}">
          {{ item.name }}
        </template>
      </VSelect>

      <ValidationProvider :name="name" :rules="rules" v-slot="{errors}" tag="div" class="flex-grow-1">
        <VTextField v-model="mobile" v-bind="inputAttrs" :error-messages="errors[0]" :prefix="prefix" />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import {VTextField, VSelect} from 'vuetify/lib'
import {isObject} from '../../lib/utils'

export default {
  name: 'CInput',
  inheritAttrs: false,
  components: {VTextField, VSelect},
  props: {
    value: {type: String, default: null},
    label: {type: String, default: null},
    clearable: {type: Boolean, default: true},
    innerLabel: {type: Boolean, default: false},
    searchForm: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null},
    inputClass: {type: String, default: null},
    inputStyle: {type: String, default: null},
    code: {type: String, default: 'ES'},
    name: {
      type: String,
      default: function () {
        return this.label
      }
    }
  },
  data() {
    const countryCodes = this.$store.state.$countryCodes
    const codeInfo = countryCodes.find((item) => item.abbreviation === this.code)
    const prefix = codeInfo && codeInfo.code
    return {
      countryCodes,
      mobile: null,
      prefix
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          value = value.replace(this.prefix, '')
        }
        this.mobile = value
      },
      immediate: true
    },
    code: {
      handler(value) {
        if (!value) {
          this.countryCode = 'ES'
        } else {
          this.setPrefix(value)
        }
      },
      immediate: true
    },
    mobile() {
      this.updateMobile()
    }
  },
  computed: {
    mobileRules() {
      if (typeof this.rules === 'string') {
        return `${this.rules}|mobile:${this.prefix}`
      } else if (isObject(this.rules)) {
        return {
          ...this.rules,
          mobile: this.prefix
        }
      } else {
        return this.rules
      }
    },
    countryCode: {
      get() {
        return this.code
      },
      set(val) {
        this.$emit('update:code', val)
      }
    },
    inputAttrs() {
      const attrs = {
        ...this.$attrs,
        name: this.name,
        outlined: true,
        class: this.inputClass,
        style: this.inputStyle,
        dense: true
      }

      if (this.searchForm || this.innerLabel) {
        attrs.label = this.label
      }
      if (this.searchForm) {
        attrs.hideDetails = 'auto'
      }
      return attrs
    }
  },
  methods: {
    updateMobile() {
      const value = this.mobile ? this.prefix + this.mobile : null
      this.$emit('input', value)
    },
    setPrefix(abbreviation) {
      const codeInfo = this.countryCodes.find((item) => item.abbreviation === abbreviation)
      if (codeInfo) {
        this.prefix = codeInfo.code
        this.updateMobile()
      }
    }
  }
}
</script>
