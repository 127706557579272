export default {
  stage: 'جایگاه',
  stages: 'جایگاه‌ها',
  addNewStage: 'افزودن جایگاه جدید',
  editStage: 'ویرایش جایگاه',
  stagesList: 'لیست جایگاه‌ها',
  stageRemoved: 'جایگاه حذف شد',
  stageUpdated: 'جایگاه به‌روزرسانی شد',
  stageAdded: 'جایگاه افزوده شد',
  evaluationConfig: 'سطح ارزیابی',
  evaluationConfigInfo: {
    BEGINNER: 'مبتدی',
    INTERMEDIATE: 'متوسط',
    ADVANCED: 'پیشرفته',
    GOLD: 'نخبه'
  }
}
