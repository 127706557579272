export default {
  license: 'License',
  licenses: 'Licenses',
  licensesList: 'Licenses List',
  addNewLicense: 'Add new license',
  editLicense: 'Edit license',
  licenseRemoved: 'License removed',
  licenseUpdated: 'License updated',
  timeCondition: 'Time condition',
  timeConditionInfo: {
    ANY: 'Infinite',
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
    YEAR: 'Year'
  }
}
