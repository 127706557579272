import ApiBase from '@/modules/core/api-base'

export class Movements extends ApiBase {
  constructor() {
    const path = 'admin/movements'
    super(path)
  }
}

export default {
  movements: new Movements()
}
