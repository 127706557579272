export default {
  500: 'خطای داخلی سرور',
  USERNAME_PASSWORD_NOT_MATCH: 'نام کاربری یا کلمه عبور صحیح نمی باشد',
  USER_NOT_FOUND: 'نام کاربری وارد شده یافت نشد',
  BAD_REQUEST: 'درخواست اشتباه',
  INVALID_DATA_TYPE: 'نام کاربری وارد شده صحیح نمی باشد',
  USERNAME_ALREADY_EXIST: 'نام کاربری تکراری است',
  ILLEGAL_ARGUMENT: 'کد وارد شده صحیح نیست',
  ILLEGAL_REQUEST: 'درخواست مجاز نیست',
  CHILD_RECORD_FOUND: 'حذف امکان پذیر نیست',
  SESSION_EXPIRED: 'زمان نشست منقضی شد',
  VALIDATION_EXCEPTION: 'ورودی معتبر نیست',
  ACCESS_DENIED: 'دسترسی مجاز نیست',
  SERVER_ERROR: 'خطای داخلی سرور',
  DUPLICATE_REQUEST: 'درخواست تکراری است',
  LOGICAL_UN_AUTHORIZED: 'عملیات مجاز نیست',
  USER_NOT_ACTIVE: 'حساب کاربری فعال نیست ',
  MOBILE_NOT_CONFIRM: 'شماره همراه تایید نشده است',
  DATA_NOT_FOUND: 'داده یافت نشد',
  INVALID_TOKEN_HEADER: 'شما از حساب کاربری خود خارج شدید',
  TOKEN_VERIFICATION_EXPIRED: 'نشست شما منقضی شد',
  UNHANDLED_ERROR: 'خطای نامشخص. کدخطا: {errorCode}',
  1204: 'خطا در ذخیره سازی فایل',

  3101: 'نام کاربری (موبایل/ایمیل) تکراری است',
  563255: 'امکان تغییر نوع رقابت برای رقابت دارای راند وجود ندارد',
  5454855: ''
}
