import ApiBase from '@/modules/core/api-base'

export class Licenses extends ApiBase {
  constructor() {
    const path = 'admin/financial/licenses'
    super(path)
  }
}

export default {
  licenses: new Licenses()
}
