<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{errors}" tag="div">
    <label v-if="!searchForm && !innerLabel && label" class="mb-1 d-block"> {{ label }} </label>
    <VCombobox ref="input" v-bind="inputAttrs" v-on="$listeners" :error-messages="errors[0]">
      <template v-slot:selection="{attrs, item, parent, selected}">
        <v-chip v-bind="attrs" :input-value="selected" label small>
          <span class="me-2"> {{ item }} </span>
          <v-icon small  @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template></VCombobox
    >
  </ValidationProvider>
</template>

<script>
import {VCombobox} from 'vuetify/lib'

export default {
  name: 'CSelect',
  inheritAttrs: false,
  components: {VCombobox},
  props: {
    label: {type: String, default: null},
    innerLabel: {type: Boolean, default: false},
    searchForm: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null},
    inputClass: {type: String, default: null},
    inputStyle: {type: String, default: null},
    name: {
      type: String,
      default: function () {
        return this.label
      }
    }
  },
  computed: {
    inputAttrs() {
      const attrs = {
        ...this.$attrs,
        name: this.name,
        outlined: true,
        class: this.inputClass,
        style: this.inputStyle,
        dense: true,
        clearable: true
      }
      if (this.innerLabel) {
        attrs.label = this.label
      }
      if (this.searchForm) {
        attrs.label = this.label
        attrs.hideDetails = 'auto'
        attrs.class = 'ma-1'
      }
      return attrs
    }
  }
}
</script>
