<template>
  <v-expansion-panels class="mb-4" focusable >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('base.search') }}
        <template v-slot:actions>
          <v-icon color="primary"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="mx-n4 pt-2">
        <div class="d-inline-flex flex-wrap mb-3">
          <slot></slot>
        </div>

        <div class="ma-1">
          <v-btn color="success" @click="submit" :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('base.applyFilter') }}
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SearchForm',
  computed: {
    progressing() {
      return this.$parent.progressing
    }
  },
  methods: {
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
