<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{errors}" tag="div">
    <label v-if="!searchForm && !innerLabel && label" class="mb-1 d-block"> {{ label }} </label>
    <component ref="input" :is="component" v-bind="inputAttrs" v-on="$listeners" :error-messages="errors[0]">
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </component>
  </ValidationProvider>
</template>

<script>
import {VAutocomplete, VFileInput, VSelect, VTextarea, VTextField} from 'vuetify/lib'

export default {
  name: 'CInput',
  inheritAttrs: false,
  components: {VTextField, VSelect, VTextarea, VAutocomplete, VFileInput},
  props: {
    label: {type: [String], default: null},
    select: {type: Boolean, default: false},
    file: {type: Boolean, default: false},
    autoComplete: {type: Boolean, default: false},
    textarea: {type: Boolean, default: false},
    innerLabel: {type: Boolean, default: false},
    searchForm: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null},
    inputClass: {type: String, default: null},
    inputStyle: {type: String, default: null},
    name: {
      type: String,
      default: function () {
        return this.label
      }
    }
  },
  computed: {
    component() {
      if (this.file) {
        return 'VFileInput'
      }
      if (this.autoComplete) {
        return 'VAutocomplete'
      }
      return this.select ? 'VSelect' : this.textarea ? 'VTextarea' : 'VTextField'
    },
    inputAttrs() {
      const attrs = {
        ...this.$attrs,
        name: this.name,
        outlined: true,
        class: this.inputClass,
        style: this.inputStyle,
        dense: true
      }
      if (this.select || this.autoComplete) {
        attrs.clearable = true
      }
      if (this.searchForm || this.innerLabel) {
        attrs.label = this.label
      }
      if (this.searchForm) {
        attrs.hideDetails = 'auto'
        attrs.class = 'ma-1'
      }
      return attrs
    }
  }
}
</script>
