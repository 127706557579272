export default {
  rank: 'رنک',
  ranks: 'رنک‌ها',
  addNewRank: 'افزودن رنک جدید',
  editRank: 'ویرایش رنک',
  ranksList: 'لیست رنک‌ها',
  rankRemoved: 'رنک حذف شد',
  rankUpdated: 'رنک به‌روزرسانی شد',
  rankAdded: 'رنک افزوده شد',
  calorieRatio: 'ضریب کالری'
}
