<template>
  <div class="d-flex">
    <v-btn v-if="!noSubmit" color="success" @click="emitSubmit" :loading="loading" class="me-3">
      {{ btnText }}
    </v-btn>
    <slot></slot>

    <v-btn color="secondary" @click="back" class="">
      {{ $t('base.return') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {
    edit: Boolean,
    flat: Boolean,
    loading: Boolean,
    noSubmit: Boolean
  },
  data() {
    return {
      btnText: this.edit ? this.$t('base.save') : this.$t('base.add')
    }
  },
  methods: {
    emitSubmit() {
      this.$emit('submit')
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
