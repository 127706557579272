import ApiBase from '@/modules/core/api-base'

export class Roles extends ApiBase {
  constructor() {
    const path = 'admin/roles'
    super(path)
  }
}
export class Permissions extends ApiBase {
  constructor() {
    const path = 'admin/permissions'
    super(path)
  }
}

export default {
  roles: new Roles(),
  permissions: new Permissions()
}
