export default {
  movementType: 'Movement type',
  movementTypes: 'Movement types',
  movementTypesList: 'Movement types List',
  addNewMovementType: 'Add new movement type',
  editMovementType: 'Edit movement type',
  movementTypeRemoved: 'Movement type removed',
  movementTypeUpdated: 'Movement type updated',
  movementTypeAdded: 'Movement type added'
}
