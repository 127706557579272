<template>
  <v-app>
    <v-overlay :value="loading" z-index="1000" opacity="0.15">
      <v-progress-circular indeterminate size="80" color="primary" />
    </v-overlay>

    <app-bar />
    <app-drawer />
    <app-view />
    <!-- <app-settings /> -->
  </v-app>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'index',
  components: {
    AppBar: () => import('./AppBar'),
    AppDrawer: () => import('./Drawer'),
    AppSettings: () => import('./Settings'),
    AppView: () => import('./View')
  },
  data() {
    return {
      loading: false
    }
  },
  watch: {
    '$store.state.$account'(val) {
      if (!val) {
        let path = '/login'
        if (this.$route.path !== this.$config.homeUrl && this.$route.path !== '/login') {
          path = `/login?returnUrl=${this.$route.path}`
        }
        this.$router.replace(path).catch(() => {})
      }
    }
  },
  methods: {
    ...mapMutations({
      logout: 'LOGOUT'
    })
  }
}
</script>
