<template>
  <numeric-display :value="amount" v-bind="$attrs" :prefix="prefix" />
</template>

<script>
import NumericDisplay from '@/components/base/NumericDisplay'
export default {
  name: 'CurrencyDisplay',
  inheritAttrs: false,
  components: {
    NumericDisplay
  },
  props: {
    value: {type: [String, Number], default: null},
    currency: {
      type: Object,
      default: function () {
        return this.$store.getters.displayCurrency
      }
    }
  },
  computed: {
    amount() {
      if (this.$isEmpty(this.value)) {
        return null
      }

      let amount = +this.value
      if (isNaN(amount)) {
        return 'NaN'
      }
      return amount / this.currency.rate
    },
    prefix() {
      return this.currency.symbol
    }
  }
}
</script>
