import modules from '../index.js'

export function moduleI18n() {
  const appDictionary = {}

  for (const {name, i18n} of modules) {
    if (i18n) {
      Object.keys(i18n).forEach((locale) => {
        if (locale in appDictionary) {
          appDictionary[locale][name] = i18n[locale]
        } else {
          appDictionary[locale] = {[name]: i18n[locale]}
        }
      })
    }
  }
  return appDictionary
}

export function moduleRoutes() {
  const appRoutes = []
  for (const {routes} of modules) {
    if (routes) {
      appRoutes.push(...routes)
    }
  }
  return appRoutes
}

export function moduleApi() {
  const appApi = {}

  for (const {api} of modules) {
    if (api) {
      Object.entries(api).forEach(([serviceName, service]) => {
        if (serviceName in appApi) {
          console.warn(`Duplicated Service Api, Service ${serviceName} already exists`)
        }
        appApi[serviceName] = service
      })
    }
  }
  return appApi
}
