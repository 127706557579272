import axios from 'axios'

import {parse, stringify} from 'qs'
import store from '@/store'
import config from '@/config'
import {isEmpty} from '@/lib/utils'
export default class ApiCore {
  constructor(path) {
    this._config = config
    this._path = path
    this._store = store
  }

  async _request({method, params, body, action, headers = {}}) {
    const url = [this._config.apiServerUrl, this._path, action].filter((part) => part).join('/')

    const req = {
      url,
      method,
      params,
      paramsSerializer: {encode: parse, serialize: stringify, arrayFormat: 'repeat'},
      headers: {'Content-Type': 'application/json', ...headers}
    }

    if (body) {
      req.data = body
    }
    const account = this._store.state.$account

    if (account) {
      req.headers.Authorization = 'Bearer ' + account.accessToken
    }
    try {
      const res = await axios(req.url, req)
      return res.data
    } catch (err) {
      if (isEmpty(err)) throw err

      if (err.message === 'Network Error') {
        this._store.commit('SET_OFFLINE')
        throw err
      }

      const {response} = err
      if (isEmpty(response)) {
        const exceptions = [{status: response.status, code: 'UNKNOWN', errorCode: -1}]
        throw exceptions
      }

      if ([403, 406].includes(response.status)) {
        this._store.commit('LOGOUT')
      }

      if ([401].includes(response.status)) {
        this._store.commit('LOGOUT')
        // router.replace('/access-denied')
      }

      if (isEmpty(response.data)) {
        const exceptions = [{status: response.status, code: 'UNKNOWN', errorCode: -1}]
        throw exceptions
      }

      let exceptions = response.data
      if (!Array.isArray(exceptions)) {
        exceptions = [exceptions]
      }

      throw exceptions.map((item) => ({
        ...item,
        status: response.status
      }))
    }
  }
}
