export default {
  ticketRemoved: 'تیکت حذف شد',
  ticketUpdated: 'تیکت به روز رسانی شد',
  ticketAdded: 'تیکت افزوده شد',
  tickets: 'تیکت‌ها',
  ticket: 'تیکت',
  subject: 'موضوع',
  text: 'متن',
  addNewTicket: 'افزودن تیکت جدید',
  editTicket: 'ویرایش تیکت',
  ticketsList: 'لیست تیکت‌ها',
  newMessage: 'پیام جدید',
  messages: 'پیام‌ها',
  messageSent: 'پیام ارسال شد',
  send: 'ارسال',
  priority: 'اولویت',
  priorityInfo: {
    URGENT: 'اضطراری',
    HIGH: 'بالا',
    NORMAL: 'عادی',
    LOW: 'پایین',
    UNKNOWN: 'نامعلوم'
  },
  statusInfo: {
    PENDING: 'در انتظار',
    IN_PROGRESS: 'در حال بررسی',
    PENDING_BY_USER: 'در انتظار کاربر',
    CLOSED: 'بسته شده'
  }
}
