import ApiBase from '@/modules/core/api-base'

export class Ranks extends ApiBase {
  constructor() {
    const path = 'admin/ranks'
    super(path)
  }
}

export default {
  ranks: new Ranks()
}
