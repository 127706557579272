export default {
  workoutBasic: 'Exercise program',
  workoutBasics: 'Exercise programs',
  workoutBasicsList: 'Exercise programs List',
  addNewWorkoutBasic: 'Add new exercise program',
  editWorkoutBasic: 'Edit exercise program',
  workoutBasicDetails: 'Exercise program details',
  workoutBasicRemoved: 'Exercise program removed',
  workoutBasicUpdated: 'Exercise program updated',
  workoutBasicAdded: 'Exercise program added',
  optional: 'Optional',
  weekCount: 'Week count'
}
