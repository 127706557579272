import ApiBase from '@/modules/core/api-base'

export class WorkoutSteps extends ApiBase {
  constructor() {
    const path = 'admin/mgm/workout-flows'
    super(path)
  }

  updateSortOrders(body) {
    return this._request({
      action: 'sort-order',
      method: 'PUT',
      body
    })
  }
}

export default {
  workoutSteps: new WorkoutSteps()
}
