<template>
  <span>
    <span v-if="label"> {{ label }} : </span>
    <span class="black--text">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'CLabel',
  props: {
    label: {type: String, default: null}
  }
}
</script>
