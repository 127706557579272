export default {
  500: 'Internal server error',
  400: 'Invalid data format',
  USERNAME_PASSWORD_NOT_MATCH: 'Username or password is not correct',
  USER_NOT_FOUND: 'User not found',
  BAD_REQUEST: 'Bad request',
  INVALID_DATA_TYPE: 'Invalid data type',
  USERNAME_ALREADY_EXIST: 'Username already exist',
  ILLEGAL_ARGUMENT: 'Illegal argument',
  ILLEGAL_REQUEST: 'Illegal request',
  CHILD_RECORD_FOUND: 'Unable to delete',
  SESSION_EXPIRED: 'Session expired',
  VALIDATION_EXCEPTION: 'Validation exception',
  ACCESS_DENIED: 'Access denied',
  SERVER_ERROR: 'Internal server error',
  DUPLICATE_REQUEST: 'Duplicate request',
  LOGICAL_UN_AUTHORIZED: 'Logical un authorized',
  USER_NOT_ACTIVE: 'User not active',
  MOBILE_NOT_CONFIRM: 'Mobile not confirm',
  DATA_NOT_FOUND: 'Data not found',
  INVALID_TOKEN_HEADER: 'You are logout from your account',
  TOKEN_VERIFICATION_EXPIRED: 'Token verification expired',
  UNHANDLED_ERROR: 'Unknown error [code: {errorCode}, status: {status}]',
  3101: 'Username (mobile/email) already exist'
}
