export default {
  workoutStep: 'گام',
  workoutSteps: 'گام‌ها',
  workoutStepsList: 'لیست گام‌ها',
  addNewWorkoutStep: 'افزودن گام جدید',
  editWorkoutStep: 'ویرایش گام',
  workoutStepRemoved: 'گام حذف شد',
  workoutStepUpdated: 'گام به‌روزرسانی شد',
  workoutStepAdded: 'گام افزوده شد',
  workoutStepsSorted: 'گام‌ها مرتب شدند'
}
