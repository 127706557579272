import ApiBase from '@/modules/core/api-base'

export class Payments extends ApiBase {
  constructor() {
    const path = 'admin/financial/payments'
    super(path)
  }
}

export default {
  payments: new Payments()
}
