export default {
  payment: 'پرداخت',
  payments: 'پرداخت ها',
  paymentRemoved: 'پرداخت حذف شد',
  paymentUpdated: 'پرداخت به‌روزرسانی شد',
  paymentAdded: 'پرداخت افزوده شد',
  verified: 'تایید شده',
  receiptNumber: 'شماره رسید',
  payDate: 'تاریخ پرداخت',
  verifiedDate: 'تاریخ تایید'
}
