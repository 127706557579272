<template>
  <ValidationProvider :name="name" :rules="rules" tag="div" v-slot="{errors}">
    <label v-if="!searchForm && !innerLabel && label" class="mb-1 d-block"> {{ label }} </label>
    <NumericField v-model="model" v-bind="inputAttrs" v-on="listeners" :error-messages="errors[0]" />
  </ValidationProvider>
</template>

<script>
import NumericField from './NumericField'

export default {
  name: 'CurrencyInput',
  inheritAttrs: false,
  components: {NumericField},
  props: {
    value: {type: [String, Number], default: null},
    currency: {
      type: Object,
      default: function (){
        return this.$store.getters.displayCurrency
      }
    },
    label: {type: String, default: null},
    innerLabel: {type: Boolean, default: false},
    searchForm: {type: Boolean, default: false},
    rules: {type: [String, Object], default: null},
    inputClass: {type: String, default: null},
    inputStyle: {type: String, default: null},
    name: {
      type: String,
      default: function () {
        return this.label
      }
    }
  },
  computed: {
    model: {
      get() {
        return this.toDisplayCurrency(this.value)
      },
      set(val) {
        const newValue = this.toBaseCurrency(val)
        this.$emit('input', newValue)
      }
    },
    inputAttrs() {
      const attrs = {
        ...this.$attrs,
        name: this.name,
        outlined: true,
        class: this.inputClass,
        style: this.inputStyle,
        dense: true,
        prefix: this.currency.symbol
      }
      if (this.innerLabel) {
        attrs.label = this.label
      }
      if (this.searchForm) {
        attrs.label = this.label
        attrs.hideDetails = 'auto'
        attrs.class = 'ma-1'
      }
      return attrs
    },
    listeners() {
      const listeners = {...this.$listeners}
      delete listeners.input
      return listeners
    }
  },
  methods: {
    toBaseCurrency(value) {
      if (this.$isEmpty(value)) return null
      return value * (this.currency.rate || 1)
    },
    toDisplayCurrency(value) {
      if (this.$isEmpty(value)) return null
      return value / (this.currency.rate || 1)
    }
  }
}
</script>
