import {moduleApi} from '@/modules/core'
import store from '../store'
const api = moduleApi(store)
export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $api: {
        get() {
          return api
        }
      }
    })
  }
}
