<template>
  <span class="position-relative">
    <v-card class="d-flex align-center justify-center pa-1" v-bind="attrs" v-on="$listeners" elevation="3">
      <slot>
        <img v-if="isImage" alt="image" class="img-fluid img-grow" :width="width" :height="height" :src="staticUrl" />

        <video v-else-if="isVideo" class="img-fluid img-grow">
          <source :src="staticUrl" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div v-else>{{ extension }}</div>
      </slot>
    </v-card>

    <v-icon
      v-if="removable"
      color="error"
      class="white rounded elevation-1"
      @click.stop="emitRemove()"
      small
      :style="{position: 'absolute', top: '5px', left: '5px', zIndex: 8}"
    >
      mdi-close
    </v-icon>
  </span>
</template>

<script>
export default {
  name: 'FileDisplay',
  props: {
    value: {type: [String], default: null},
    width: {type: [Number, String], default: 75},
    height: {type: [Number, String], default: 75},
    link: {type: Boolean, default: true},
    removable: {type: Boolean, default: false}
  },
  computed: {
    isLinkable() {
      return this.value && this.link
    },
    attrs() {
      const attrs = {
        elevation: 1,
        width: this.width,
        ...this.$attrs
      }
      if (this.isLinkable) {
        attrs.tag = 'a'
        attrs.href = this.staticUrl
        attrs.target = '_blank'
      }
      return attrs
    },
    staticUrl() {
      return `${this.$config.staticServerUrl}/${this.value}`
    },
    isImage() {
      const imageExtensions = new Set(['jpeg', 'jpg', 'png', 'gif'])
      return imageExtensions.has(this.extension)
    },
    isVideo() {
      const videoExtensions = new Set(['mp4', 'ogg', 'webm'])
      return videoExtensions.has(this.extension)
    },
    extension() {
      return this.value.split('.').pop().toLowerCase()
    }
  },
  methods: {
    emitRemove() {
      this.$emit('remove', this.value)
    }
  }
}
</script>
