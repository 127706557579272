import Vue from 'vue'
import AutoComplete from '@/components/base/AutoComplete'
import CInput from '@/components/base/CInput'
import CLabel from '@/components/base/CLabel'
import CurrencyDisplay from '@/components/base/CurrencyDisplay'
import DateDisplay from '@/components/base/DateDisplay'
import DatePicker from '@/components/base/DatePicker'
import ExportExcel from '@/components/base/ExportExcel'
import FilesDisplay from '@/components/base/FilesDisplay'
import MapLocation from '@/components/base/MapLocation'
import FormCard from '@/components/base/FormCard.vue'
import MobileInput from '@/components/base/MobileInput'
import NumericDisplay from '@/components/base/NumericDisplay'
import PageFooter from '@/components/base/PageFooter'
import PageLoading from '@/components/base/PageLoading'
import SearchForm from '@/components/base/SearchForm'
import Thumbnail from '@/components/base/Thumbnail'
import TimeDisplay from '@/components/base/TimeDisplay'
import TimeInput from '@/components/base/TimeInput'
import Uploader from '@/components/base/Uploader'
import CSelect from '@/components/base/CSelect'
import HtmlEditor from '@/components/base/HtmlEditor'
import CTags from '@/components/base/CTags'
import CSwitch from '@/components/base/CSwitch'
import NumericInput from '@/components/base/NumericInput'
import CurrencyInput from '@/components/base/CurrencyInput'
import BooleanDisplay from '@/components/base/BooleanDisplay'
import FieldDictionary from '@/components/base/FieldDictionary'
import DictionaryDisplay from '@/components/base/DictionaryDisplay'
import NoRecords from '@/components/base/NoRecords.vue'

Vue.component('DictionaryDisplay', DictionaryDisplay)
Vue.component('FieldDictionary', FieldDictionary)
Vue.component('BooleanDisplay', BooleanDisplay)
Vue.component('NumericInput', NumericInput)
Vue.component('CurrencyInput', CurrencyInput)
Vue.component('AutoComplete', AutoComplete)
Vue.component('CSwitch', CSwitch)
Vue.component('CTags', CTags)
Vue.component('HtmlEditor', HtmlEditor)
Vue.component('CSelect', CSelect)
Vue.component('CSelect', CSelect)
Vue.component('CInput', CInput)
Vue.component('CLabel', CLabel)
Vue.component('CurrencyDisplay', CurrencyDisplay)
Vue.component('DateDisplay', DateDisplay)
Vue.component('DatePicker', DatePicker)
Vue.component('ExportExcel', ExportExcel)
Vue.component('FilesDisplay', FilesDisplay)
Vue.component('MapLocation', MapLocation)
Vue.component('FormCard', FormCard)
Vue.component('MobileInput', MobileInput)
Vue.component('NoRecords', NoRecords)
Vue.component('NumericDisplay', NumericDisplay)
Vue.component('PageFooter', PageFooter)
Vue.component('PageLoading', PageLoading)
Vue.component('SearchForm', SearchForm)
Vue.component('Thumbnail', Thumbnail)
Vue.component('TimeDisplay', TimeDisplay)
Vue.component('TimeInput', TimeInput)
Vue.component('Uploader', Uploader)
