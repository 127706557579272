export default {
  workoutConfigItem: 'حرکت تمرین',
  workoutConfigItems: 'حرکات تمرین',
  workoutConfigItemsList: 'لیست حرکات تمرین',
  addNewWorkoutConfigItem: 'افزودن حرکت تمرین جدید',
  editWorkoutConfigItem: 'ویرایش حرکت تمرین',
  workoutConfigItemRemoved: 'حرکت تمرین حذف شد',
  workoutConfigItemUpdated: 'حرکت تمرین به‌روزرسانی شد',
  workoutConfigItemAdded: 'حرکت تمرین افزوده شد',
  complexity: 'درجه سختی'
}
