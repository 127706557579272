const name = 'creditTransactions'
const path = 'credit-transactions'
const permissions = ['credit-transactions.operations']

export default [
  {
    name: name,
    path: `/${path}`,
    meta: {label: 'creditTransactions.creditTransactions', auth: permissions},
    redirect: `/${path}/index`,
    component: {
      render: (h) => h('router-view')
    },
    children: [
      {
        name: `${name}Index`,
        path: 'index',
        meta: {label: 'base.index', auth: permissions, title: 'base.index'},
        component: () => import('./pages/index.vue')
      },
      {
        name: `${name}Increment`,
        path: 'increment',
        meta: {label: 'creditTransactions.increment', auth: permissions, title: 'creditTransactions.increment'},
        component: () => import('./pages/Increment.vue')
      },
      {
        name: `${name}Decrement`,
        path: 'decrement',
        meta: {label: 'creditTransactions.decrement', auth: permissions, title: 'creditTransactions.decrement'},
        props: true,
        component: () => import('./pages/Decrement.vue')
      },
      {
        name: `${name}View`,
        path: 'view/:id',
        meta: {label: 'base.view', auth: permissions, title: 'base.view'},
        props: true,
        component: () => import('./pages/View.vue')
      }
    ]
  }
]
