<template>
  <v-dialog v-model="open" :title="$t('base.editImage')" max-width="600" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Privacy Policy </v-card-title>

      <v-card-text>
        <vue-cropper ref="cropper" alt="Source Image" :aspect-ratio="aspectRatio"> </vue-cropper>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div class="flex-grow-1">
          <div class="mb-2 d-flex justify-end">
            <v-btn v-for="item in actionButtons" :key="item.icon" class="me-2 mb-2" color="primary" small v-on="item.vOn" icon fab>
              <v-icon v-text="item.icon" />
            </v-btn>
          </div>

          <div>
            <v-btn color="primary" @click="cropImage" class="me-3 mb-3">
              {{ $t('base.save') }}
            </v-btn>

            <v-btn color="secondary" @click="hide" class="me-3 mb-3">
              {{ $t('base.close') }}
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  name: 'ImageCropper',
  props: {
    aspectRatio: {type: Number, default: 1}
  },
  components: {VueCropper},
  data() {
    return {
      open: false,

      imagesCount: 1,
      finalImages: [],
      flip: {
        x: 1,
        y: 1
      },
      actionButtons: [
        {icon: 'mdi-magnify-plus', vOn: {click: this.zoomIn}},
        {icon: 'mdi-magnify-minus', vOn: {click: this.zoomOut}},
        {icon: 'mdi-rotate-right', vOn: {click: this.rotateRight}},
        {icon: 'mdi-rotate-left', vOn: {click: this.rotateLeft}},
        {icon: 'mdi-flip-vertical', vOn: {click: this.flipY}},
        {icon: 'mdi-flip-horizontal', vOn: {click: this.flipX}}
      ]
    }
  },

  methods: {
    resetState() {
      this.imagesCount = 1
      this.finalImages = []
      this.images = []
    },
    emitEnd() {
      this.$emit('end', this.finalImages)
      this.resetState()
    },
    init(images) {
      this.show()
      this.imagesCount = images.length
      this.images = images
      this.setImage(images[0])
    },
    changeExtensionBasedOnMimeType(fileName, mimeType) {
      const name = fileName.split('.').slice(0, -1).join('.')
      const ext = this.getMimeTypeExtension(mimeType)
      return `${name}.${ext}`
    },
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const fileName = this.changeExtensionBasedOnMimeType(`image${Math.random() * 100000}`, blob.type)
        const file = new File([blob], fileName)
        this.finalImages.push(file)
        if (this.finalImages.length < this.imagesCount) {
          this.setImage(this.images[this.finalImages.length])
        } else {
          this.hide()
        }
      })
    },
    getMimeTypeExtension(mimeType) {
      switch (mimeType) {
        case 'image/jpeg':
          return 'jpg'
        case 'image/bmp':
          return 'bmp'
        case 'image/tiff':
          return 'tif'
        case 'image/x-icon':
          return 'ico'
        case 'image/png':
        default:
          return 'png'
      }
    },
    zoomIn() {
      this.$refs.cropper.relativeZoom(0.5)
    },
    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.5)
    },
    rotateLeft() {
      this.$refs.cropper.rotate(-90)
    },
    rotateRight() {
      this.$refs.cropper.rotate(90)
    },
    flipX() {
      this.flip.x = -this.flip.x
      this.$refs.cropper.scaleX(this.flip.x)
    },
    flipY() {
      this.flip.y = -this.flip.y
      this.$refs.cropper.scaleY(this.flip.y)
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg)
    },
    setCropBoxData() {
      if (!this.data) return
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data))
    },
    setData() {
      if (!this.data) return
      this.$refs.cropper.setData(JSON.parse(this.data))
    },
    setImage(file) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    zoom(percent) {
      this.$refs.cropper.zoom(percent)
    },
    hide() {
      this.open = false
      this.emitEnd()
    },
    show() {
      this.open = true
    }
  }
}
</script>
