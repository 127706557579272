import ApiBase from '@/modules/core/api-base'

export default class Files extends ApiBase {
  constructor() {
    const path = 'pub/files/temp'
    super(path)
  }

  upload(model) {
    return this._request({
      method: 'POST',
      body: model,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
