import ApiBase from '@/modules/core/api-base'

export class Levels extends ApiBase {
  constructor() {
    const path = 'admin/levels'
    super(path)
  }
}

export default {
  levels: new Levels()
}
