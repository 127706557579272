import ApiBase from '@/modules/core/api-base'

export class Public extends ApiBase {
  constructor() {
    const path = 'pub'
    super(path)
  }

  getSettings(filter) {
    return this._request({
      action: 'setting',
      method: 'GET',
      params: filter
    })
  }

  getCountryCodes(filter) {
    return this._request({
      action: 'country-codes',
      method: 'GET',
      params: filter
    })
  }
}
