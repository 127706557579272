<template>
  <VTextField v-model="model" v-bind="$attrs" v-on="listeners" />
</template>

<script>
import {VTextField} from 'vuetify/lib'

export default {
  name: 'NumericField',
  inheritAttrs: false,
  components: {VTextField},
  props: {
    value: {type: [String, Number], default: null}
  },
  data() {
    return {
      model: this.humanize(this.value)
    }
  },
  watch: {
    value(newValue) {
      this.model = this.humanize(newValue)
    },
    model(value, oldValue) {
      if (isNaN(+value)) {
        this.$nextTick(() => {
          this.model = this.humanize(oldValue)
        })
      } else if (this.numberFormatter(this.model) !== this.numberFormatter(value)) {
        this.$nextTick(() => {
          this.model = this.humanize(value)
        })
      }
      this.syncValue()
    }
  },
  computed: {
    listeners() {
      const listeners = {...this.$listeners}
      delete listeners.input
      return listeners
    }
  },
  methods: {
    syncValue() {
      if (this.isChange()) {
        this.$emit('input', this.numberFormatter(this.model))
      }
    },
    isChange() {
      if (!this.$isEmpty(this.value) && !this.$isEmpty(this.model)) {
        return +this.value !== +this.model
      } else {
        return this.value !== this.model
      }
    },
    getNumericParts(value) {
      return String(value).replace(/[^0-9.]/g, '')
    },
    isNumber(number) {
      number = +this.getNumericParts(number)
      return !isNaN(number)
    },
    numberFormatter(value) {
      if (this.$isEmpty(value)) return null
      return +String(value)
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*?)\..*/g, '$1')
    },
    humanize(number) {
      number = this.numberFormatter(this.getNumericParts(number))
      if (this.$isEmpty(number)) return null
      const options = {
        maximumFractionDigits: 20
      }
      return number.toLocaleString('en-us', options)
    }
  }
}
</script>
