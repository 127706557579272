export default {
  movementEvaluation: 'Movement evaluation',
  movementEvaluations: 'Movement evaluations',
  movementEvaluationsList: 'Movement evaluations List',
  addNewMovementEvaluation: 'Add new movement evaluation',
  editMovementEvaluation: 'Edit movement evaluation',
  movementEvaluationRemoved: 'Movement evaluation removed',
  movementEvaluationUpdated: 'Movement evaluation updated',
  movementEvaluationAdded: 'Movement evaluation added',
  activityTime: 'Activity duration',
  repeatCount: 'Repeat count',
  restTime: 'Rest duration',
  setCount: 'Set count'
}
