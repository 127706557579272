export default {
  level: 'سطح',
  levels: 'سطح‌ها',
  addNewLevel: 'افزودن سطح جدید',
  editLevel: 'ویرایش سطح',
  levelsList: 'لیست سطح‌ها',
  levelRemoved: 'سطح حذف شد',
  levelUpdated: 'سطح به‌روزرسانی شد',
  levelAdded: 'سطح افزوده شد'
}
