<template>
  <c-input
    v-model="time"
    @blur="completeFormat"
    v-mask="'##:##'"
    @input="validateTime"
    v-bind="$attrs"
    placeholder="12:45"
    dir="ltr"
    @keyup="formatHours"
  />
</template>

<script>
export default {
  name: 'TimeInput',
  props: {
    value: {type: String, default: ''}
  },
  data() {
    return {
      time: this.value || '',
      invalidHours: new Set([4, 5, 6, 7, 8, 9]),
      invalidMinutes: new Set([6, 7, 8, 9])
    }
  },
  watch: {
    value(val) {
      this.time = val || ''
    },
    time(val) {
      this.$emit('input', val)
    }
  },
  computed: {
    currentHours() {
      if (this.$isEmpty(this.time)) {
        return ''
      }
      return this.time.split(':')[0]
    },
    currentMinutes() {
      if (this.$isEmpty(this.time) || !this.time.includes(':')) {
        return ''
      }
      return this.time.split(':')[1] || ''
    }
  },
  methods: {
    normalize(val) {
      return ('00' + val).slice(-2)
    },
    validateTime() {
      if (this.$isEmpty(this.time) && this.time !== '') {
        this.time = ''
      }
      let targetTime = null
      let hours = this.currentHours
      let minutes = this.currentMinutes
      if (this.$isEmpty(minutes)) {
        if (this.$isEmpty(hours)) {
          targetTime = ''
        } else if (hours > 23) {
          targetTime = `23:`
        } else if (hours > 9) {
          targetTime = `${hours}:`
        } else {
          targetTime = `${hours}`
        }
      } else if (minutes < 59) {
        if (this.$isEmpty(hours)) {
          targetTime = ''
        } else if (hours > 23) {
          targetTime = `23:${minutes}`
        } else if (hours > 9) {
          targetTime = `${hours}:${minutes}`
        } else {
          targetTime = `${hours}:${minutes}`
        }
      } else {
        if (this.$isEmpty(hours)) {
          targetTime = ''
        } else if (hours > 23) {
          targetTime = `23:59`
        } else if (hours > 9) {
          targetTime = `${hours}:`
        } else {
          targetTime = `${hours}:`
        }
      }
      this.$nextTick(() => {
        this.time = targetTime
      })
    },
    formatHours(e) {
      if (e.keyCode === 186) {
        if (this.time && !this.time.includes(':')) {
          this.$nextTick(() => {
            this.time = this.normalize(this.currentHours)
          })
        }
      }
    },
    completeFormat() {
      if (this.$isEmpty(this.time)) {
        this.time = ''
        return
      }
      this.time = `${this.normalize(this.currentHours)}:${this.normalize(this.currentMinutes)}`
    }
  }
}
</script>
