import ApiBase from '@/modules/core/api-base'

export class WorkoutBasics extends ApiBase {
  constructor() {
    const path = 'admin/mgm/workout-basics'
    super(path)
  }
}

export class WorkoutBaseLines extends ApiBase {
  constructor() {
    const path = 'admin/mgm/workout-baselines'
    super(path)
  }
}

export default {
  workoutBasics: new WorkoutBasics(),
  workoutBaseLines: new WorkoutBaseLines()
}
