export default {
  user: 'کاربر',
  users: 'کاربران',
  addNewUser: 'افزودن کاربر جدید',
  editUser: 'ویرایش کاربر',
  usersList: 'لیست کاربران',
  userRemoved: 'کاربر حذف شد',
  userUpdated: 'کاربر به‌روزرسانی شد',
  userAdded: 'کاربر افزوده شد',
  gender: 'جنسیت',
  birthDate: 'تاریخ تولد',
  userRoles: 'نقش های کاربر',
  mobileConfirmed: 'تایید موبایل',
  twoFactorEnabled: 'ورود دو مرحله ای',
  editUserRoles: 'ویرایش نقش های کاربر',
  userRolesUpdated: 'نقش های کاربر به‌روزرسانی شد'
  // others
}
