export default {
  movement: 'Movement',
  movements: 'Movements',
  movementsList: 'Movements List',
  addNewMovement: 'Add new movement',
  editMovement: 'Edit movement',
  movementRemoved: 'Movement removed',
  movementUpdated: 'Movement updated',
  movementAdded: 'Movement added',
  defaultRepeatCount: 'Default repeat count',
  defaultSetCount: 'Default set count',
  complexities: 'Complexities',
  valueType: 'Type',
  valueTypeInfo: {
    TIME: 'Time',
    COUNT: 'Count'
  }
}
