<template>
  <img :src="url" v-bind="$attrs" alt="image" :width="width" :height="height" v-on="$listeners" class="align-middle ma-1 rounded" />
</template>

<script>
export default {
  name: 'Thumbnail',
  inheritAttrs: false,
  props: {
    value: {type: String, default: null},
    placeholder: {type: String, default: '/img/user-placeholder.png'},
    width: {type: [Number, String], default: 50},
    height: {type: [Number, String], default: 50}
  },
  computed: {
    url() {
      if (this.$isEmpty(this.value)) {
        return this.placeholder
      }
      return `${this.$config.staticServerUrl}/${this.value}`
    }
  }
}
</script>
