import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib'
import i18n from './i18n'
import '@/sass/overrides.scss'
import config from '@/config'

Vue.use(Vuetify)
const theme = {
  primary: '#98267A',
  secondary: '#798EA4',
  accent: '#82B1FF',
  error: '#E94B3C',
  info: '#00758F',
  success: '#0c9d2f',
  warning: '#FA7A35'
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  rtl: config.rtl,
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
