export default {
  equipment: 'تجهیز',
  equipments: 'تجهیزات',
  addNewEquipment: 'افزودن تجهیز جدید',
  editEquipment: 'ویرایش تجهیز',
  equipmentsList: 'لیست تجهیزات',
  equipmentRemoved: 'تجهیز حذف شد',
  equipmentUpdated: 'تجهیز به‌روزرسانی شد',
  equipmentAdded: 'تجهیز افزوده شد'
}
