<template>
  <span v-if="!$isEmpty(value)" dir="ltr">
    <template v-if="prefix">{{ prefix }}</template>
    <template> {{ number }} </template>
    <template v-if="suffix">{{ suffix }}</template>
  </span>
</template>

<script>
export default {
  name: 'NumericDisplay',
  props: {
    value: {type: [String, Number], default: null},
    localeString: {type: Boolean, default: true},
    prefix: {type: String, default: null},
    suffix: {type: String, default: null}
  },
  computed: {
    number() {
      if (this.$isEmpty(this.value)) return null
      let numberValue = +this.value
      if (isNaN(numberValue)) return 'NaN'

      if (this.localeString) {
        numberValue = numberValue.toLocaleString()
      }
      return numberValue
    }
  }
}
</script>
