export default {
  ticketRemoved: 'Ticket removed',
  ticketUpdated: 'Ticket updated',
  ticketAdded: 'Ticket added',
  tickets: 'Tickets',
  ticket: 'Ticket',
  addNewTicket: 'Add new ticket',
  newMessage: 'New message',
  send: 'Send',
  editTicket: 'Edit ticket',
  ticketsList: 'Tickets List',
  subject: 'Subject',
  text: 'Text',
  messages: 'Messages',
  priority: 'Priority',
  priorityInfo: {
    URGENT: 'urgent',
    HIGH: 'high',
    NORMAL: 'normal',
    LOW: 'low',
    UNKNOWN: 'unknown'
  },
  statusInfo: {
    PENDING: 'Pending',
    IN_PROGRESS: 'In progress',
    PENDING_BY_USER: 'Pending by user',
    CLOSED: 'Closed'
  }
}
