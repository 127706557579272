<template>
  <c-input
    auto-complete
    v-model="model"
    v-bind="$attrs"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :clearable="!multiple"
    :chips="multiple"
    :small-chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    @change="emitChange"
    :hide-selected="!multiple"
    :menu-props="{closeOnContentClick: !multiple}"
    :hide-no-data="progressing"
    :loading="progressing"
    cache-items
    :search-input.sync="term"
    @focus="onFocus"
    @blur="onBlur"
  >
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>

    <template v-if="!multiple" #selection="data">
      <slot name="selection" v-bind="data">
        <span class="d-inline-block me-1">{{ getText(data.item) }}</span>
      </slot>
    </template>
  </c-input>
</template>

<script>
export default {
  name: 'AutoComplete',
  inheritAttrs: false,
  props: {
    value: {type: [Number, String, Array, Object], default: null},
    select: {type: [Function, Object, Promise, Array], default: null},
    search: {type: [Function, Promise], default: null},
    extendFilter: {type: Object, default: () => ({})},
    itemText: {type: [String, Function], default: 'name'},
    itemValue: {type: [String, Function], default: 'id'},
    multiple: {type: Boolean, default: false},
    reduce: {type: Function, default: null},
    searchKey: {
      type: String,
      default() {
        return this.itemText
      }
    }
  },
  data() {
    return {
      progressing: false,
      isFocused: false,
      term: null,
      items: []
    }
  },
  watch: {
    term(newValue, oldValue) {
      if (newValue != oldValue) {
        this.searchTerm()
      }
    }
  },
  async created() {
    await this.setSelected()
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true
      this.searchTerm()
    },
    onBlur() {
      this.isFocused = false
      this.term = null
    },
    getText(item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      } else {
        return item[this.itemText]
      }
    },
    emitChange(value) {
      if (!value) {
        this.$emit('change', null)
      }
      const item = this.items.find((i) => {
        return i[this.itemValue] === value
      })
      this.$emit('change', item)
    },
    async searchTerm() {
      if (!this.isFocused) {
        return
      }
      this.items = []
      this.progressing = true
      const filter = this.extendFilter
      if (this.term) {
        filter[this.searchKey] = this.term
      } else {
        delete filter[this.searchKey]
      }
      try {
        let items = await this.search(filter)
        if (this.reduce) {
          items = this.reduce(items)
        }
        this.items = items
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    isFunction(value) {
      return typeof value === 'function' && {}.toString.call(value) === '[object Function]'
    },
    async setSelected(select = this.select) {
      if (!select) {
        return
      }
      if (this.isFunction(select)) {
        select = select()
      }
      try {
        this.progressing = true
        let res = await Promise.resolve(select)
        this.$emit('initialize', res)
        if (this.$isEmpty(res)) {
          res = []
        }
        if (!Array.isArray(res)) {
          res = [res]
        }
        this.items = res
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    }
  }
}
</script>
