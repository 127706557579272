export default [
  {
    name: 'TOS',
    path: '/terms-of-service',
    meta: {
      layout: 'empty',
      title: 'terms of service',
      label: 'terms of service',
      auth: true
    },
    component: () => import('../pages/Privacy.vue')
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    meta: {
      layout: 'empty',
      title: 'privacy policy',
      label: 'privacy policy',
      auth: true
    },
    component: () => import('../pages/Policy.vue')
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    meta: {
      title: 'base.dashboard',
      label: 'base.dashboard',
      auth: 'Idn'
    },
    component: () => import('../pages/Dashboard')
  },
  {
    name: 'home',
    path: '/',
    redirect: '/dashboard'
  },
  {
    name: 'Login',
    path: '/login',
    meta: {
      layout: 'empty',
      title: 'base.login',
      label: 'base.login',
      auth: true
    },
    component: () => import('../pages/Login')
  },
  {
    name: 'AccessDenied',
    path: '/access-denied',
    meta: {
      layout: 'empty',
      title: 'base.accessDenied',
      label: 'base.accessDenied',
      auth: true
    },
    component: () => import('../pages/AccessDenied')
  },
  {
    name: 'Permissions',
    path: '/permissions',
    meta: {
      layout: 'empty',
      title: 'base.permissions',
      label: 'base.permissions',
      auth: 'Idn'
    },
    component: () => import('../pages/Permissions')
  },
  {
    name: 'Profile',
    path: '/profile',
    meta: {
      title: 'base.profile',
      label: 'base.profile',
      auth: 'Idn'
    },
    component: () => import('../pages/Profile')
  },
  {
    name: 'AccountRemoval',
    path: '/account-removal',
    meta: {
      layout: 'empty',
      title: 'base.removeAccount',
      label: 'base.removeAccount',
      auth: true
    },
    component: () => import('../pages/AccountRemoval')
  },
  {
    name: 'LoginByOTP',
    path: '/login-otp',
    meta: {
      layout: 'empty',
      title: 'base.loginViaOTP',
      label: 'base.loginViaOTP',
      auth: true
    },
    component: () => import('../pages/LoginOTP')
  },
  {
    name: 'Register',
    path: '/register',
    meta: {
      layout: 'empty',
      title: 'base.register',
      label: 'base.register',
      auth: true
    },
    component: () => import('../pages/Register')
  },
  {
    name: 'ResetPassword',
    path: '/reset-password',
    meta: {
      layout: 'empty',
      title: 'base.resetPassword',
      label: 'base.resetPassword',
      auth: true
    },
    component: () => import('../pages/ResetPassword')
  },
  {
    name: 'ChangePassword',
    path: '/change-password',
    meta: {
      title: 'base.changePassword',
      label: 'base.changePassword',
      auth: 'Idn'
    },
    component: () => import('../pages/ChangePassword')
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    meta: {
      layout: 'empty',
      title: 'base.forgotPassword',
      label: 'base.forgotPassword',
      auth: true
    },
    component: () => import('../pages/ForgotPassword')
  },
  {
    name: 'PageNotFound',
    path: '*',
    meta: {
      layout: 'empty',
      title: 'base.pageNotFound',
      label: 'base.pageNotFound',
      auth: true
    },
    component: () => import('../pages/404')
  }
]
