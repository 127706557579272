const name = 'payments'
const path = '/payments'
const permission = ['payment.operations']

export default [
  {
    name: name,
    path: path,
    meta: {label: 'payments.payments', auth: permission},
    redirect: `${path}/index`,
    component: {
      render: (h) => h('router-view')
    },
    children: [
      {
        name: `${name}Index`,
        path: 'index',
        meta: {label: 'base.index', auth: permission, title: 'base.index'},
        component: () => import('./pages/index.vue')
      },
      {
        name: `${name}View`,
        path: 'view/:id',
        meta: {label: 'base.view', auth: permission, title: 'base.view'},
        props: true,
        component: () => import('./pages/View.vue')
      }
    ]
  }
]
