<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{errors, valid, validated, dirty}" tag="div">
    <label v-if="label" class="d-block mb-1" :class="{'label-invalid': dirty || validated ? !valid : false}">
      {{ label }}
    </label>
    <div class="my-1" v-if="dirty || validated ? !valid : false">{{ errors[0] }}</div>
    <editor :init="config" :api-key="apiKey" v-bind="$attrs" v-on="$listeners" />
  </ValidationProvider>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'HtmlEditor',
  props: {
    rules: {type: [Object, String], default: null},
    label: {type: String, default: null},
    name: {
      type: String,
      default() {
        return this.label
      }
    }
  },
  components: {
    editor: Editor
  },
  data() {
    const vm = this
    return {
      apiKey: 'z2fvgb12fid20qablvolrzqahufdng4v0sjz28p7hxhp9w9u',
      config: {
        plugins: 'lists link image table code help wordcount',
        directionality: 'rtl',
        height: '700',
        language: 'fa',
        language_url: 'langs/fa-IR.js',
        file_picker_callback: function (callback, _value, _meta) {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')

          input.addEventListener('change', (e) => {
            const file = e.target.files[0]

            const model = new FormData()
            model.append('files', file)
            vm.$api.files.upload(model).then((res) => {
              const url = vm.$config.createStaticUrl(res[0])
              callback(url, {width: null, height: null})
            })
          })

          input.click()
        }
      }
    }
  }
}
</script>
