<template>
  <v-icon v-if="value" color="success"> mdi-check</v-icon>
  <v-icon v-else color="secondary"> mdi-close</v-icon>
</template>

<script>
export default {
  name: 'BooleanDisplay',
  props: {
    value: {type: Boolean, default: false}
  }
}
</script>
