import ApiBase from '@/modules/core/api-base'

export class Stages extends ApiBase {
  constructor() {
    const path = 'admin/stages'
    super(path)
  }
}

export default {
  stages: new Stages()
}
