import ApiBase from '@/modules/core/api-base'

export class WorkoutConfigs extends ApiBase {
  constructor() {
    const path = 'admin/mgm/workout-configs'
    super(path)
  }
}

export default {
  workoutConfigs: new WorkoutConfigs()
}
