import base from '@/modules/base'
import geolocations from '@/modules/geolocations'
import users from '@/modules/users'
import roles from '@/modules/roles'
import movements from '@/modules/movements'
import bodyParts from './bodyParts'
import levels from './levels'
// import movementGroups from './movementGroups'
import movementTypes from './movementTypes'
import ranks from './ranks'
import stages from './stages'
import creditTransactions from './creditTransactions'
import paymentConfigs from './paymentConfigs'
import payments from './payments'
import tickets from './tickets'
import equipments from './equipments'
import workoutBasics from './workoutBasics'
import workoutConfigs from './workoutConfigs'
import workoutConfigItems from './workoutConfigItems'
import movementEvaluations from './movementEvaluations'
import workoutSteps from './workoutSteps'
import licenses from './licenses'

export default [
  base,
  bodyParts,
  movements,
  licenses,
  ranks,
  equipments,
  workoutBasics,
  workoutConfigs,
  workoutSteps,
  workoutConfigItems,
  movementEvaluations,
  stages,
  // movementGroups,
  movementTypes,
  levels,
  geolocations,
  roles,
  users,
  creditTransactions,
  paymentConfigs,
  payments,
  tickets
]
