import ApiBase from '@/modules/core/api-base'

export class BodyParts extends ApiBase {
  constructor() {
    const path = 'admin/body-parts'
    super(path)
  }
}

export default {
  bodyParts: new BodyParts()
}
