export default {
  user: 'User',
  users: 'Users',
  usersList: 'Users List',
  addNewUser: 'Add new user',
  editUser: 'Edit user',
  userRemoved: 'User removed',
  userUpdated: 'User updated',
  userAdded: 'User added',
  mobileConfirmed: 'Mobile confirmed',
  gender: 'Gender',
  userRoles: 'User roles',
  birthDate: 'Birth data',
  twoFactorEnabled: 'Two factor enabled',
  editUserRoles: 'Edit user roles',
  userRolesUpdated: 'User roles updated'
}
