const name = 'roles'
const path = '/roles'
const permission = ['roles.operations']

export default [
  {
    name: name,
    path: path,
    meta: {label: 'roles.roles', auth: permission},
    redirect: `${path}/index`,
    component: {
      render: (h) => h('router-view')
    },
    children: [
      {
        name: `${name}Index`,
        path: 'index',
        meta: {label: 'base.index', auth: permission, title: 'base.index'},
        component: () => import('./pages/index.vue')
      },
      {
        name: `${name}Create`,
        path: 'create',
        meta: {label: 'base.add', auth: permission, title: 'base.add'},
        component: () => import('./pages/Create.vue')
      },
      {
        name: `${name}Edit`,
        path: 'edit/:id',
        meta: {label: 'base.edit', auth: permission, title: 'base.edit'},
        props: true,
        component: () => import('./pages/Edit.vue')
      }
    ]
  }
]
