export default {
  workoutBasic: 'برنامه تمرین',
  workoutBasics: 'برنامه‌های تمرین',
  workoutBasicsList: 'لیست برنامه‌های تمرین',
  addNewWorkoutBasic: 'افزودن برنامه تمرین جدید',
  editWorkoutBasic: 'ویرایش برنامه تمرین',
  workoutBasicDetails: 'جزئیات برنامه تمرین',
  workoutBasicRemoved: 'برنامه تمرین حذف شد',
  workoutBasicUpdated: 'برنامه تمرین به‌روزرسانی شد',
  workoutBasicAdded: 'برنامه تمرین افزوده شد',
  optional: 'اختیاری',
  weekCount: 'تعداد هفته'
}
