export default {
  bodyPart: 'Body part',
  bodyParts: 'Body parts',
  bodyPartsList: 'Body parts List',
  addNewBodyPart: 'Add new body part',
  mainBodyPart: 'Main body part',
  editBodyPart: 'Edit body part',
  bodyPartRemoved: 'Body part removed',
  bodyPartUpdated: 'Body part updated',
  bodyPartAdded: 'Body part added'
}
