export default {
  index: 'لیست',
  monday: 'دوشنبه',
  tuesday: 'سه‌شنبه',
  wednesday: 'چهارشنبه',
  thursday: 'پنج‌شنبه',
  friday: 'جمعه',
  saturday: 'شنبه',
  sunday: 'یک‌شنبه',
  admin: 'ادمین',
  profile: 'پروفایل',
  logout: 'خروج',
  login: 'ورود',
  EUR: 'یورو',
  USD: 'دلار',
  accessDenied: 'شما اجازه دسترسی به این محتوا را ندارید ',
  images: 'تصاویر',
  videos: 'ویدیوها',
  dashboard: 'داشبورد',
  altName: 'عنوان دوم',
  register: 'ثبت نام',
  baseInfo: 'اطلاعات پایه',
  editImage: 'ویرایش تصویر',
  availableFrom: 'تاریخ شروع',
  price: 'قیمت',
  backgroundImage: 'تصویر پس زمینه',
  womenVideos: 'ویدیوهای زنانه',
  womenImages: 'تصاویر زنانه',
  period: 'مدت زمان',
  headerImage: 'تصویر header',
  otherImages: 'سایر تصاویر',
  content: 'محتوا',
  tag: 'تگ',
  tags: 'تگ‌ها',
  startDate: 'تاریخ شروع',
  others: 'سایر',
  availableUntil: 'تاریخ پایان',
  passwordConfirm: 'تایید رمز عبور',
  forgotPassword: 'فراموشی رمز عبور',
  dontHaveAnAccount: 'حساب کاربری ندارید؟',
  JoinFreeToday: 'رایگان ثبت نام کن',
  haveAnAccount: 'حساب کاربری دارید؟',
  enterYourEmail: 'ایمیل خود را وارد کنید',
  enterYourFirstName: 'نام خود را وارد کنید',
  enterYourLastName: 'نام خانوادگی خود را وارد کنید',
  enterYourFullName: 'نام و نام خانوادگی خود را وارد کنید',
  enterYourMobile: 'شماره موبایل خود را وارد کنید',
  enterYourPassword: 'رمز عبور خود را وارد کنید',
  verifyMobileNumber: 'تایید شماره موبایل',
  resetPassword: 'بازیابی رمز عبور',
  enterTheCodeIsSentToYourMobileOrEmail: 'کد تایید به شماره موبایل یا ایمیل شما ارسال شد',
  enterYourPasswordAgain: 'رمز عبور خود را دوباره وارد کنید',
  loginViaOTP: 'ورود با کد امنیتی',
  loginViaPassword: 'ورود با رمز عبور',
  profileUpdated: 'پروفایل به روز شد',
  recoveryCodeSentToYourMobile: 'کد بازیابی به شماره موبایل شما ارسال شد',
  changePassword: 'تغییر رمز عبور',
  resendCode: 'ارسال مجدد کد',
  loginByPassword: 'ورود با رمز عبور',
  sendOtp: 'ارسال کد امنیتی',
  passwordChanged: 'رمز عبور با موفقیت تغییر یافت',
  completeYourProfile: 'تکمیل پروفایل خود',
  createAccount: 'ثبت نام',
  loginNow: 'ورود شوید',
  getStartWithApp: 'شروع کار با @:(base.appTitle)',
  loginTOYourAccount: 'وارد حساب کاربری خود شوید',
  forgetPassword: 'فراموشی رمز عبور',
  emailOrUsername: 'ایمیل یا نام کاربری',
  welcomeBack: 'خوش آمدید',

  actions: 'عملیات',
  active: 'فعال',
  add: 'افزودن',
  search: 'جستجو',
  zipCode: 'کد پستی',
  min: 'حداقل',
  max: 'حداکثر',
  all: 'همه',
  minAmount: 'حداقل قیمت',
  maxAmount: 'حداکثر قیمت',
  operator: 'اپراتور',
  lastModified: 'آخرین تغییرات',
  selectAll: 'انتخاب همه',
  free: 'رایگان',
  response: 'پاسخ',
  description: 'توضیحات',
  text: 'متن',
  seconds: 'ثانیه',
  minutes: 'دقیقه',
  date: 'تاریخ',
  hours: 'ساعت',
  id: 'شناسه',
  subItems: 'زیر مجموعه',
  cancel: 'کنسل',
  durationUnit: 'واحد مدت زمان',
  duration: 'مدت زمان',
  number: 'عدد',
  video: 'ویدیو',
  address: 'آدرس',
  inactive: 'غیرفعال',
  price: 'قیمت',
  discount: 'تخفیف',
  exportExcel: 'خروجی اکسل',
  print: 'پرینت',
  discountType: 'نوع تخفیف',
  applyFilter: 'اعمال فیلتر',
  areYouSureToRemove: 'آیا میخواهید این آیتم را حذف کنید؟',
  choose: 'انتخاب کنید',
  chooseFile: 'فایل را انتخاب کنید',
  confirmPassword: 'تایید رمز عبور',
  delete: 'حذف',
  submit: 'ثبت',
  percent: 'درصد',
  selectAddress: 'انتخاب آدرس',
  close: 'بستن',
  amount: 'مبلغ',
  value: 'مقدار',
  created: 'تاریخ ایجاد',
  createdMin: 'تاریخ ایجاد از',
  createdMax: 'تاریخ ایجاد تا',
  IRT: 'تومان',
  IRR: 'ریال',
  basicInfo: 'اطلاعات اولیه',
  fromCreated: 'تاریخ ایجاد از',
  toCreated: 'تاریخ ایجاد تا',
  image: 'تصویر',
  economicCode: 'کد اقتصادی',
  edit: 'ویرایش',
  email: 'ایمیل',
  firstName: 'نام',
  lastName: 'نام خانوادگی',
  fullName: 'نام و نام خانوادگی',
  logo: 'لوگو',
  mobile: 'موبایل',
  youLogouted: 'از حساب کاربری خارج شدید',
  done: 'انجام شده',
  status: 'وضعیت',
  canceled: 'کنسل شده',
  areYourSureToDo: 'آیا از انجام این کار اطمینان دارید؟',
  clock: 'ساعت',
  createdDate: 'تاریخ ایجاد',
  name: 'نام',
  nationalCode: 'کد ملی',
  nationalId: 'شماره شناسنامه',
  networkError: 'خطا در اتصال به شبکه',
  no: 'خیر',
  noRecords: 'موردی برای نمایش وجود ندارد',
  password: 'رمز عبور',
  phone: 'تلفن',
  plate: 'پلاک',
  return: 'بازگشت',
  save: 'ذخیره',
  settings: 'تنظیمات',
  title: 'عنوان',
  unit: 'واحد',
  username: 'نام کاربری',
  verified: 'تایید شده',
  view: 'مشاهده',
  warning: 'هشدار',
  sms: 'پیامک',
  yes: 'بله',
  uniqueId: 'شناسه یکتا',
  oldPassword: 'رمز عبور فعلی',
  otpSentToYourMobile: 'رمز یکبار مصرف به موبایل شما ارسال شد',
  newPassword: 'رمز عبور جدید',
  forLoginEnterYourUsername: 'برای ورود موبایل خود را وارد کنید',
  forLoginEnterYourEmail: 'برای ورود ایمیل خود را وارد کنید',
  code: 'کد',
  type: 'نوع',
  fax: 'فکس',
  assign: 'اختصاص',
  pageNotFound: 'متاسفانه صفحه مورد نظر یافت نشد',
  tryAgain: 'چند لحظه دیگر دوباره تلاش کنید',
  returnHome: 'بازگشت به صفحه اصلی',
  toResendAgain: 'تا ارسال دوباره',
  enterYourMobileNumber: 'شماره موبایل خود را وارد کنید',
  hello: 'سلام',
  editMobile: 'اصلاح شماره',
  location: 'لوکیشن',
  day: 'روز',
  country: 'کشور',
  province: 'استان',
  male: 'مرد',
  female: 'زن',
  city: 'شهر',
  district: 'منطقه',
  month: 'ماه',
  week: 'هفته',
  times: 'بار',
  year: 'سال',
  android: 'اندروید',
  web: 'وب',
  details: 'جزئیات',
  ios: 'ios',
  sortOrder: 'ترتیب نمایش'
}
