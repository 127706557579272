export default {
  actions: 'Actions',
  active: 'Active',
  add: 'Add',
  address: 'Address',
  admin: 'Admin',
  amountMin: 'Amount from',
  amountMax: 'Amount to',
  accessDenied: "You don't have permission to access this area",
  all: 'All',
  altName: 'Alt name',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  USD: 'Dollar',
  EUR: 'Euro',
  removeAccount: 'Remove account',
  accountRemoval: 'Account removal',
  areYouSureToRemoveYourAccount: 'Are you sure to remove your account?',
  IfYouWantToDeleteYourAccountPleaseClickOnThisLink: 'If you want to delete your account please click on this button',
  amount: 'Amount',
  price: 'Price',
  editImage: 'Edit image',
  backgroundImage: 'Background image',
  womenVideos: 'Women videos',
  womenImages: 'Women images',
  period: 'Period',
  weekDay: 'Week day',
  basicInfo: 'Basic Info',
  android: 'Android',
  applyFilter: 'Apply filter',
  areYouSureToRemove: 'Are you sure to remove?',
  assign: 'Assign',
  availableFrom: 'Available from',
  availableUntil: 'Available until',
  baseInfo: 'Base information',
  basicInfo: 'Basic info',
  cancel: 'Cancel',
  canceled: 'Canceled',
  changePassword: 'Change password',
  choose: 'Choose',
  chooseFile: 'Choose file',
  city: 'City',
  clock: 'Clock',
  close: 'Close',
  code: 'Code',
  completeYourProfile: 'Complete your profile',
  confirmPassword: 'Confirm password',
  content: 'Content',
  country: 'Country',
  createAccount: 'Create account',
  created: 'Created',
  createdDate: 'Created Date',
  createdMax: 'Created until',
  createdMin: 'Created from',
  dashboard: 'Dashboard',
  date: 'Date',
  day: 'Day',
  delete: 'Delete',
  description: 'Description',
  details: 'Details',
  discount: 'Discount',
  discountType: 'Discount type',
  district: 'District',
  done: 'Done',
  dontHaveAnAccount: "Don't have an account?",
  duration: 'Duration',
  durationUnit: 'Duration unit',
  economicCode: 'Economic code',
  edit: 'Edit',
  editMobile: 'Edit mobile',
  email: 'Email',
  emailOrUsername: 'Email or Username',
  enterTheCodeIsSentToYourMobileOrEmail: 'Enter the code is sent to your mobile or email',
  enterYourEmail: 'Enter your email',
  enterYourFirstName: 'Enter your first name',
  enterYourFullName: 'Enter your full name',
  enterYourLastName: 'Enter your last name',
  enterYourMobile: 'Enter your mobile',
  enterYourMobileNumber: 'Enter your mobile number',
  enterYourPassword: 'Enter your password',
  enterYourPasswordAgain: 'Enter your password again',
  exportExcel: 'Export excel',
  fax: 'Fax',
  female: 'Female',
  firstName: 'First name',
  forgetPassword: 'Forget password',
  forgotPassword: 'Forgot password',
  forLoginEnterYourEmail: 'For login enter your email',
  forLoginEnterYourUsername: 'For login enter your username',
  free: 'Free',
  fromCreated: 'From created',
  fullName: 'Full name',
  getStartWithApp: 'Get Started With @:(base.appTitle)',
  haveAnAccount: 'Have an account?',
  headerImage: 'Header image',
  hello: 'Hello',
  hours: 'Hours',
  id: 'Id',
  image: 'Image',
  images: 'Images',
  index: 'Index',
  ios: 'Ios',
  IRR: 'IRR',
  IRT: 'IRT',
  JoinFreeToday: 'Join free today',
  lastModified: 'Last modified',
  lastName: 'Last name',
  location: 'Location',
  login: 'Login',
  loginByPassword: 'Login by password',
  loginNow: 'Login now',
  loginTOYourAccount: 'Login to your account',
  loginViaOTP: 'Login via OTP',
  loginViaPassword: 'Login via password',
  logo: 'Logo',
  logout: 'Logout',
  male: 'Male',
  max: 'Max',
  min: 'Min',
  minutes: 'Minutes',
  mobile: 'Mobile',
  month: 'Month',
  name: 'Name',
  nationalCode: 'National code',
  nationalId: 'National id',
  networkError: 'Network error',
  newPassword: 'New password',
  no: 'No',
  noRecords: 'No records',
  number: 'Number',
  oldPassword: 'Old password',
  otherImages: 'Other images',
  others: 'Others',
  otpSendedToYourMobile: 'OTP sended to your mobile',
  otpSentToYourMobile: 'OTP sent to your mobile',
  pageNotFound: 'Page not found',
  password: 'Password',
  passwordChanged: 'Password changed',
  passwordConfirm: 'Password confirm',
  percent: 'Percent',
  phone: 'Phone',
  plate: 'Plate',
  print: 'Print',
  profile: 'Profile',
  profileUpdated: 'Profile updated',
  province: 'Province',
  recoveryCodeSentToYourMobile: 'Recovery code sent to your mobile',
  register: 'Register',
  resendCode: 'Resend code',
  resetPassword: 'Reset Password',
  response: 'Response',
  return: 'Return',
  returnHome: 'Return to home',
  save: 'Save',
  search: 'Search',
  seconds: 'Seconds',
  selectAddress: 'Select address',
  selectAll: 'Select all',
  sendOtp: 'Send OTP',
  settings: 'Settings',
  sms: 'SMS',
  sortOrder: 'Sort order',
  startDate: 'Start date',
  status: 'Status',
  subItems: 'Sub items',
  submit: 'Submit',
  tag: 'Tag',
  tags: 'Tags',
  text: 'Text',
  times: 'Times',
  title: 'Title',
  toCreated: 'To created',
  toResendAgain: 'To resend again',
  tryAgain: 'Try again',
  type: 'Type',
  uniqueId: 'Unique id',
  unit: 'Unit',
  username: 'Username',
  value: 'Value',
  verified: 'Verified',
  verifyMobileNumber: 'Verify mobile number',
  video: 'Video',
  videos: 'Videos',
  view: 'View',
  warning: 'Warning',
  web: 'Web',
  week: 'Week',
  welcomeBack: 'Welcome back',
  year: 'Year',
  yes: 'Yes',
  youLogouted: 'You have been logged out',
  zipCode: 'Zip code'
}
